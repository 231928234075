/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormControlLabel, TextField } from '@mui/material';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import { ProfileUsers } from '../../../api/enumerations';
import { UserData } from '../../../api/users/types';
import {
  CustomPatternFormat,
  CustomTextField,
  SelectTextField,
} from '../../../components/CustomInput';
import { CloseIcon, FilledButton } from '../../../components/UI/Button';
import { StyledDialog } from '../../../components/UI/Dialog';
import { DialogTitle } from '../../../components/UI/Typography';
import { IconCloseMS, IconEditMS } from '../../../constants/icons';
import { selectProfileUser } from '../../../constants/selectOptions';
import { Constants } from '../../../constants/users';
import useUsers from '../hooks';
import {
  DialogContainer,
  FormStyled,
  StyledCheckbox,
  ButtonBox,
  StyledPatternFormat,
} from './styles';

export interface EditUserDialogProps {
  open: boolean;
  userData: UserData;
  handleClose(): void;
  updateTable(): void;
  userRole: number;
}

export default function EditUserDialog({
  open,
  userData,
  handleClose,
  updateTable,
  userRole,
}: EditUserDialogProps): JSX.Element {
  const {
    handleEdit,
    name,
    setName,
    email,
    setEmail,
    selectedRole,
    setSelectedRole,
    active,
    setActive,
    counselId,
    setCounselId,
    counselIdType,
    setCounselIdType,
    personalId,
    setPersonalId,
    personalIdType,
    setPersonalIdType,
    telephone,
    setTelephone,
  } = useUsers();

  useEffect(() => {
    setEmail(userData.email);
    setName(userData.name);
    setSelectedRole(userData.role);
    setActive(userData.is_active);
    setCounselId(userData.engineer_document || '');
    setCounselIdType(userData.engineer_document_type || 'CREA/CAU');
    setPersonalId(userData.personal_document || '');
    setPersonalIdType(userData.personal_document_type || 'RG/CNH');
    setTelephone(userData.phone_number || '');
  }, [userData]);

  const handleSubmit = (e: React.FormEvent): void => {
    handleEdit(e, userData).then(() => updateTable());
    handleClose();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogContainer>
        <CloseIcon onClick={handleClose}>{IconCloseMS}</CloseIcon>
        <DialogTitle>
          {IconEditMS}
          {Constants.editUser}
        </DialogTitle>
        <FormStyled component="form" id="login" onSubmit={handleSubmit}>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={active}
                onChange={() => setActive(!active)}
              />
            }
            label={Constants.active}
          />
          <Box display="flex" gap="2rem">
            <NumericFormat
              required
              disabled={!userData.first_access}
              id="counsel-id"
              color="secondary"
              customInput={TextField}
              label={counselIdType}
              value={counselId}
              onChange={(e) => setCounselId(e.target.value)}
              sx={StyledPatternFormat}
            />
            <NumericFormat
              required
              disabled={!userData.first_access}
              id="personal-id"
              color="secondary"
              customInput={TextField}
              label={personalIdType}
              value={personalId}
              onChange={(e) => setPersonalId(e.target.value)}
              sx={StyledPatternFormat}
            />
          </Box>
          <CustomTextField
            required
            id="user-name"
            label={Constants.name}
            value={name}
            setValue={setName}
          />
          <CustomTextField
            required
            id="user-email"
            label={Constants.email}
            value={email}
            setValue={setEmail}
          />
          <Box display="flex" gap="2rem">
            <SelectTextField
              id="user-role"
              label={Constants.role}
              required
              disable={userRole !== ProfileUsers.ADMIN}
              value={selectedRole}
              setValue={setSelectedRole}
              selectOptions={selectProfileUser()}
            />
            <CustomPatternFormat
              disabled={!userData.first_access}
              required
              minLength={11}
              id="telephone"
              label={Constants.telephone}
              value={telephone}
              setValue={setTelephone}
              format="(##) #####-####"
            />
          </Box>
          <ButtonBox>
            <FilledButton form="login" type="submit">
              {Constants.buttonEdit}
            </FilledButton>
          </ButtonBox>
        </FormStyled>
      </DialogContainer>
    </StyledDialog>
  );
}
