/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';

import { workOrderAPI } from '../api';
import { StatusCode } from '../api/enumerations';
import { GetAllWorkOrderData } from '../api/workOrders/types';
import { GlobalContext } from '../context/global';
import { getErrorMessage } from '../helpers';
import { getLocalStorageToken } from '../helpers/localStorage';

export function useWorkOrder(): {
  loading: boolean;
  workOrders: GetAllWorkOrderData | undefined;
  searchField: string;
  setSearchField: (value: string) => void;
  setAllowSearch: (value: boolean) => void;
  getDataCallback: () => Promise<void>;
  handleTopWorkOrders: () => Promise<void>;
} {
  const [loading, setLoading] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [allowSearch, setAllowSearch] = useState(true);
  const [workOrders, setWorkOrders] = useState<GetAllWorkOrderData>();
  const [defaultData, setDefaultData] = useState<GetAllWorkOrderData>();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage, company } =
    useContext(GlobalContext);

  const token = getLocalStorageToken();

  const getDataCallback = useCallback(
    async (searchValue = '') => {
      setLoading(true);
      try {
        const response = await workOrderAPI.getAllWorkOrders(searchValue);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (response.data) {
          setWorkOrders(response.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    },
    [company]
  );

  const handleTopWorkOrders = useCallback(async () => {
    setLoading(true);
    try {
      const { data, detail } = await workOrderAPI.getTopWorkOrders();

      if (detail.description) {
        throw new Error(detail.description);
      }

      if (detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (data) {
        setDefaultData(data);
        setWorkOrders(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, []);

  useEffect(() => {
    if (token !== null && token !== 'undefined' && allowSearch) {
      if (searchField.length > 0) {
        const delayDebounceFn = setTimeout(() => {
          getDataCallback(searchField);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
      handleTopWorkOrders();
    }
    return setWorkOrders(defaultData);
  }, [getDataCallback, company, searchField]);

  return {
    loading,
    workOrders,
    searchField,
    setSearchField,
    setAllowSearch,
    getDataCallback,
    handleTopWorkOrders,
  };
}
