import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputTitle = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '14px',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));

export const SmallStyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '100px',
  paddingLeft: '8px',
  minHeight: '28px',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100px',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiInputBase-input': { padding: '14px' },
}));

export const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const SliderGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const DeleteButton = styled(Button)(() => ({
  width: 250,
  height: 48,
  background: '#E81710 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 10,
  opacity: 1,
  display: 'flex',
  gap: 10,
  '&:hover': {
    backgroundColor: '#D0140E',
  },
}));

export const ExtrapolationsErrors = styled(Box)(() => ({
  width: '100%',
  padding: '8px 0',
  margin: '50px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  background: '#F5F7FB',
  border: '1px solid #FF334B',
  color: '#FF334B',
  fontSize: 21,
  fontWeight: 600,

  '& p': {
    fontSize: 14,
    fontWeight: 400,
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
  },
}));
export const DialogBox = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '200px',
  padding: '40px',
}));
export const CloseIcon = styled(IconButton)(({ theme }) => ({
  margin: '-30px -30px 0',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
}));
export const DialogTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: '600',
  color: '#595959',
}));
