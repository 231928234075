/* eslint-disable react/require-default-props */
import { Grid } from '@mui/material';

import {
  convertClientKind,
  convertEvaluationType,
  convertPropertyUse,
  convertReportFunction,
  convertReportGoal,
} from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { IconPersonMS } from '../../../constants/icons';
import { SectionBox } from '../../UI/Box';
import { InputTitle, SectionTitle, StyledInput } from '../../UI/Typography';
import { Constants } from './constants';
import { AttachBox } from './styles';

interface ClientDataProps {
  propertyData?: WorkOrderData;
}

export function ClientData({ propertyData }: ClientDataProps): JSX.Element {
  return (
    <SectionBox>
      <AttachBox>
        <SectionTitle>
          {IconPersonMS}
          {Constants.clientData}
        </SectionTitle>
      </AttachBox>
      <Grid container spacing={4}>
        <Grid item xs={6} xl={4}>
          <InputTitle>{Constants.clientName}</InputTitle>
          <StyledInput>{propertyData?.client_name}</StyledInput>
        </Grid>
        <Grid item xs={6} xl={4}>
          <InputTitle>{Constants.solicitor}</InputTitle>
          <StyledInput>{propertyData?.requester_name}</StyledInput>
        </Grid>
        <Grid item xs={6} xl={4}>
          <InputTitle>{Constants.goal}</InputTitle>
          <StyledInput>
            {propertyData && convertReportGoal(propertyData.report_goal)}
          </StyledInput>
        </Grid>
        <Grid item xs={6} xl={3}>
          <InputTitle>{Constants.evaluationType}</InputTitle>
          <StyledInput>
            {propertyData &&
              convertEvaluationType(propertyData.evaluation_type)}
          </StyledInput>
        </Grid>
        <Grid item xs={4} xl={3}>
          <InputTitle>{Constants.clientType}</InputTitle>
          <StyledInput>
            {propertyData && convertClientKind(propertyData.client_kind)}
          </StyledInput>
        </Grid>
        <Grid item xs={4} xl={3}>
          <InputTitle>{Constants.function}</InputTitle>
          <StyledInput>
            {propertyData &&
              convertReportFunction(propertyData.report_function)}
          </StyledInput>
        </Grid>
        <Grid item xs={4} xl={3}>
          <InputTitle>{Constants.propertyUse}</InputTitle>
          <StyledInput>
            {propertyData && convertPropertyUse(propertyData.use)}
          </StyledInput>
        </Grid>
      </Grid>
    </SectionBox>
  );
}
