import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { companyAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import {
  FilledButton,
  OutlinedButton,
  StandardButton,
} from '../../../components/UI/Button';
import { Constants } from '../../../constants/configuration';
import { IconAddPhotoAlternateMS, IconEditMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { validateHexColor } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useUploadFile from '../../../hooks/useUploadFile';
import { PageTitle } from '../styles';
import {
  BoldTypography,
  ColorBox,
  ColorContainer,
  ConfigurationsObs,
  GridContainer,
  LogoCardMedia,
  StyledTextField,
  Upload,
  UploadIcon,
  UploadSubtitle,
  UploadText,
} from './styles';

export default function Customize(): JSX.Element {
  const [file, setFile] = useState<File>();
  const [urlImage, setUrlImage] = useState('');
  const [color, setColor] = useState<string>('');

  const { dragActive, handleDrag, handleDrop, handleFileUpload } =
    useUploadFile();
  const {
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    setUpdateLogo,
    updateLogo,
  } = useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await companyAPI.getSelfCompany();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data.evaluation_primary_color) {
        setColor(response.data.evaluation_primary_color);
      }
      if (response.data.logo) {
        setUrlImage(response.data.logo);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const dropFile = (e: DragEvent<HTMLDivElement>): void => {
    setUrlImage('');
    const file = handleDrop(e);
    if (file) {
      setFile(file[0]);
    }
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>): void => {
    setUrlImage('');
    const file = handleFileUpload(e);
    if (file) {
      setFile(file[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (!validateHexColor(color)) {
      setSnackbarMessage('Cor inválida');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    if (file) {
      formData.append('logo', file);
    }
    formData.append('evaluation_primary_color', color);

    try {
      const response = await companyAPI.updateCompanyEvaluationConfig(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Ops! Algo deu errado.');
      }

      setSnackbarMessage('Relatório personalizado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (file) {
        setUpdateLogo(!updateLogo);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <Box>
      <PageTitle>{Constants.customize}</PageTitle>
      <BoldTypography>{Constants.logo}</BoldTypography>
      <Box component="form" id="customize-report" onSubmit={handleSubmit}>
        {urlImage.length > 0 ? (
          <Box mb="20px">
            <LogoCardMedia image={urlImage} title="logo" />
            <label htmlFor="uploadLogo">
              <input
                accept="image/*"
                id="uploadLogo"
                type="file"
                style={{ display: 'none' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => uploadFile(e)}
              />
              {!dragActive && (
                <StandardButton component="span">
                  {IconEditMS}
                  {Constants.changeLogo}
                </StandardButton>
              )}
            </label>
          </Box>
        ) : (
          <Box>
            {file ? (
              <Box mb="40px">
                <LogoCardMedia image={URL.createObjectURL(file)} title="logo" />
                <label htmlFor="uploadLogo">
                  <input
                    accept="image/*"
                    id="uploadLogo"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      uploadFile(e)
                    }
                  />
                  {!dragActive && (
                    <StandardButton component="span">
                      {IconEditMS}
                      {Constants.changeLogo}
                    </StandardButton>
                  )}
                </label>
              </Box>
            ) : (
              <Upload
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={dropFile}
              >
                <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                <UploadText>
                  {dragActive ? Constants.dragText : Constants.uploadText}
                </UploadText>
                <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
                <label htmlFor="uploadPictures">
                  <input
                    accept="image/*"
                    id="uploadPictures"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      uploadFile(e)
                    }
                  />
                  {!dragActive && (
                    <StandardButton component="span">
                      {IconAddPhotoAlternateMS}
                      {Constants.searchLogo}
                    </StandardButton>
                  )}
                </label>
              </Upload>
            )}
          </Box>
        )}
        <ConfigurationsObs>{Constants.logoObs}</ConfigurationsObs>
        <Divider />
        <BoldTypography>{Constants.color}</BoldTypography>
        <Typography>{Constants.primary}</Typography>
        <ColorContainer>
          <ColorBox
            required
            type="color"
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
            bgcolor={`${color}`}
          />
          <StyledTextField
            required
            id="color"
            inputProps={{ maxLength: 7 }}
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
        </ColorContainer>
        <ConfigurationsObs>{Constants.colorObs}</ConfigurationsObs>
        <Divider />
      </Box>
      <GridContainer container textAlign="center">
        <Grid item xs={4}>
          <OutlinedButton>{Constants.cancel}</OutlinedButton>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <FilledButton id="save-btn" type="submit" form="customize-report">
            {Constants.save}
          </FilledButton>
        </Grid>
      </GridContainer>
    </Box>
  );
}
