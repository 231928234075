import { TourData } from '../../api/users/types';

export default <TourData>{
  skipTour: {
    onBoarding: false,
    pipeline: false,
    pept: false,
    entrance: false,
    schedule: false,
    inspection: false,
    sampleCreate: false,
    calculation: false,
    report: false,
    revision: false,
    archived: false,
    users: false,
    configuration: false,
    form: false,
    agenda: false,
    errorReport: false,
  },
  onBoarding: { complete: false },
  pipeline: { complete: false },
  pept: { complete: false },
  entrance: { complete: false },
  schedule: { complete: false },
  inspection: [
    {
      stage: 'inspection',
      complete: false,
      step: 0,
    },
    {
      stage: 'inspectionRooms',
      complete: false,
      step: 0,
    },
    {
      stage: 'inspectionRoomsAddPhotos',
      complete: false,
      step: 0,
    },
    {
      stage: 'inspectionRoomsImageDetails',
      complete: false,
      step: 0,
    },
    {
      stage: 'inspectionPathologies',
      complete: false,
      step: 0,
    },
    {
      stage: 'inspectionGallery',
      complete: false,
      step: 0,
    },
  ],
  sampleCreate: [
    { stage: 'sampleCreate', complete: false, step: 0 },
    { stage: 'addElement', complete: false, step: 0 },
  ],
  calculation: { complete: false },
  report: { complete: false },
  revision: { complete: false },
  archived: { complete: false },
  users: { complete: false },
  configuration: { complete: false },
  form: { complete: false },
  agenda: { complete: false },
  errorReport: { complete: false },
  lastStepSeen: 0,
};
