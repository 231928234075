import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
  myHeadersWithoutToken,
} from '../global';
import {
  ChangeCardProps,
  CheckCoverageProps,
  CodeValidationDetailProps,
  CodeValidationProps,
  CompanyProps,
  DetailProps,
  IdwallNumberProps,
  RegistrationStatusProps,
  SelfCompanyData,
  SubscribeData,
  SubscribeToPlanProps,
} from './types';

const checkCoverage = (zipcode: string): Promise<CheckCoverageProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithoutToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/check-coverage?zipcode=${zipcode}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const registrationStatus = (
  email: string
): Promise<RegistrationStatusProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithoutToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/companies/registration-status?email=${email}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getSelfCompany = (): Promise<SelfCompanyData> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/me`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const createCompany = (data: CompanyProps): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithoutToken(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const updateCompanyEvaluationConfig = (
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/evaluation-config`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const codeValidation = (
  data: CodeValidationProps
): Promise<CodeValidationDetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithoutToken(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/code-validation`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

type EmailProps = {
  email: string;
};

const sendVerificationCodeByEmail = (
  data: EmailProps
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithoutToken(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/email-code`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

type SMSProps = {
  phone_number: string;
};

const sendVerificationCodeBySMS = (data: SMSProps): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithoutToken(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/sms-code`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const sendIdwallNumber = (data: IdwallNumberProps): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithoutToken(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/idwall-number`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteCompanyPlan = (): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/me/plan`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const subscribeToPlan = (
  data: SubscribeData
): Promise<SubscribeToPlanProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/me/plan`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const changeCard = (): Promise<ChangeCardProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/companies/me/billing-portal`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  checkCoverage,
  registrationStatus,
  getSelfCompany,
  createCompany,
  updateCompanyEvaluationConfig,
  codeValidation,
  sendVerificationCodeByEmail,
  sendVerificationCodeBySMS,
  sendIdwallNumber,
  deleteCompanyPlan,
  subscribeToPlan,
  changeCard,
};
