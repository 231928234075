/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, CardMedia, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { UploadBox } from '../../../components/UI/Box';

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  margin: '40px 0 20px',
}));

export const LogoCardMedia = styled(CardMedia)(() => ({
  height: '200px',
  width: '280px',
  backgroundSize: 'contain',
  borderRadius: '10px',
  border: '1px solid #D7D8DB',
  marginBottom: '20px',
}));

export const Upload = styled(UploadBox)(() => ({
  width: '390px',
  gap: '10px',
  padding: '20px',
  marginBottom: '40px',
}));

export const UploadIcon = styled(Typography)(({ theme }) => ({
  '& span': { fontSize: '52px', color: theme.palette.primary.main },
}));

export const UploadText = styled(Typography)(() => ({
  fontWeight: '600',
}));

export const UploadSubtitle = styled(Typography)(() => ({
  fontSize: '14px',
  marginBottom: '20px',
}));

export const ConfigurationsObs = styled(Typography)(() => ({
  fontSize: '14px',
  marginBottom: '20px',
}));

export const GridContainer = styled(Grid)(() => ({
  padding: '32px 0',
}));

export const ColorContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  margin: '8px 0 20px',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '200px',
  height: '48px',
  '& .MuiInputBase-root': {
    border: '1px solid #B038FA',
  },
  '& .MuiInputBase-input': {
    padding: '12px',
  },
}));

interface ColorBoxProps {
  bgcolor?: string;
}

export const ColorBox = styled('input')<ColorBoxProps>(({ bgcolor }) => ({
  cursor: 'pointer',
  width: '48px',
  height: '48px',
  overflow: 'hidden',
  backgroundColor: bgcolor,
  padding: 0,
  '&[type="color"]::-webkit-color-swatch-wrapper': {
    padding: 0,
    border: 'none',
  },
  '&[type="color"]:focus': {
    outline: 'none',
  },
}));
