/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { companyAPI } from '../../api';
import { StatusCode } from '../../api/enumerations';
import logo from '../../assets/images/proto-logo.png';
import { CustomPatternFormat } from '../../components/CustomInput';
import Snackbar from '../../components/Snackbar';
import { OutlinedButton } from '../../components/UI/Button';
import { Constants } from '../../constants/createAccount';
import { IconPersonPinPlace } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { RegistrationIdentificationContext } from '../../context/registrationIdentification';
import {
  cleanValue,
  getErrorMessage,
  getRegistrationUfPosition,
} from '../../helpers';
import { removeVerifiedCep, setVerifiedCep } from '../../helpers/cepVerified';
import {
  removeLocalStorageCompany,
  removeLocalStorageToken,
} from '../../helpers/localStorage';
import RegisterEmail from './RegisterEmail';
import {
  ButtonBox,
  Container,
  InputBox,
  Title,
  Logo,
  StyledIcon,
  StyledText,
  SubmitButton,
  UnavailableArea,
} from './styles';

export default function CreateAccount(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [unavailableCep, setUnavailableCep] = useState(false);
  const [potencialCustomerData, setPotencialCustomerData] = useState({
    city: '',
    uf: 0,
  });
  const [cep, setCep] = useState('');

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { reset } = useContext(RegistrationIdentificationContext);

  const navigate = useNavigate();

  useEffect(() => {
    removeLocalStorageToken();
    removeLocalStorageCompany();
    reset();
    removeVerifiedCep();
  }, []);

  const navigateToLogin = (): void => navigate('/login');

  const verifyCep = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    const cepValue = cleanValue(cep);
    if (cepValue.length < 8) {
      setSnackbarMessage('CEP inválido');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    try {
      const { detail, data } = await companyAPI.checkCoverage(cepValue);
      if (detail.status_code === StatusCode.NOT_FOUND) {
        setUnavailableCep(true);
      }

      if (detail.city && detail.uf) {
        setPotencialCustomerData({
          city: detail.city,
          uf: getRegistrationUfPosition(detail.uf),
        });
      }

      if (detail.description) {
        throw new Error(detail.description);
      }

      if (data) {
        setVerifiedCep(cep);
        setLoading(false);
        navigate('/create-account/register');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoading(false);
    }
  };
  return (
    <Container>
      <Logo onClick={navigateToLogin}>
        <img src={logo} alt="Logo do Proto" />
      </Logo>
      <Title>
        {unavailableCep ? Constants.unavailable : Constants.welcome}
      </Title>
      {unavailableCep ? (
        <InputBox>
          <StyledIcon color="primary">{IconPersonPinPlace}</StyledIcon>
          {Constants.unavailableArea.map((line) => (
            <UnavailableArea key={line}>
              {line
                .split('*')
                .map((text, index) =>
                  index % 2 === 0 ? text : <span key={text}>{text}</span>
                )}
            </UnavailableArea>
          ))}
          <ButtonBox>
            <OutlinedButton onClick={navigateToLogin}>
              {Constants.later}
            </OutlinedButton>
            <RegisterEmail
              city={potencialCustomerData.city}
              uf={potencialCustomerData.uf}
            />
          </ButtonBox>
        </InputBox>
      ) : (
        <InputBox component="form" id="verify-cep" onSubmit={verifyCep}>
          <StyledText>{Constants.verifyCEP}</StyledText>
          <Box>
            <CustomPatternFormat
              required
              minLength={8}
              id="cep"
              label={Constants.cep}
              value={cep}
              setValue={setCep}
              format="#####-###"
            />
          </Box>
          <SubmitButton form="verify-cep" type="submit" disabled={loading}>
            {loading ? (
              <CircularProgress color="inherit" size={22} />
            ) : (
              Constants.confirm
            )}
          </SubmitButton>
        </InputBox>
      )}
      {openSnackbar && <Snackbar />}
    </Container>
  );
}
