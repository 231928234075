import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxContainer = styled(Box)(() => ({
  margin: '20px 60px 60px 60px',
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const FlexSpaceBetweenBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const ButtonsSpace = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
}));

export const SubmitBox = styled(FlexSpaceBetweenBox)(() => ({
  margin: '60px 0',
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '4px 0 40px 20px',
  alignItems: 'center',
  gap: '20px',
}));

export const UploadBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // eslint-disable-next-line max-len
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23595959FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: '30px',
}));

export const CancelOsBox = styled(Box)(() => ({
  width: '210px',
  height: '32px',
}));

export const FlexBoxGap = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '32px',
}));

export const PagesContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '2rem 0',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const LoadingBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '160px 0',
}));
