import { useEffect } from 'react';

import {
  Status,
  convertStatus,
  convertStatusUrl,
} from '../../../api/enumerations';
import { GetAllWorkOrderData } from '../../../api/workOrders/types';
import { Column } from './Column';
import { PipelineContainer } from './styles';

interface PipelineViewProps {
  workOrders: GetAllWorkOrderData | undefined;
  pipeLoading: boolean;
  isSearching: boolean;
  setAllowSearch: (value: boolean) => void;
}

export default function PipelineView({
  workOrders,
  pipeLoading,
  isSearching,
  setAllowSearch,
}: PipelineViewProps): JSX.Element {
  const pipelineColumn = [
    {
      id: 'entrance',
      name: convertStatus(Status.ENTRANCE),
      workOrders: workOrders?.[Status.ENTRANCE],
      link: convertStatusUrl(Status.ENTRANCE),
      status: Status.ENTRANCE,
    },
    {
      id: 'pept',
      name: convertStatus(Status.PEPT),
      workOrders: workOrders?.[Status.PEPT],
      link: convertStatusUrl(Status.PEPT),
      status: Status.PEPT,
    },
    {
      id: 'schedule',
      name: convertStatus(Status.SCHEDULE),
      workOrders: workOrders?.[Status.SCHEDULE],
      link: convertStatusUrl(Status.SCHEDULE),
      status: Status.SCHEDULE,
    },
    {
      id: 'inspection',
      name: convertStatus(Status.INSPECTION),
      workOrders: workOrders?.[Status.INSPECTION],
      link: convertStatusUrl(Status.INSPECTION),
      status: Status.INSPECTION,
    },
    {
      id: 'sample-creation',
      name: convertStatus(Status.SAMPLE_CREATION),
      workOrders: workOrders?.[Status.SAMPLE_CREATION],
      link: convertStatusUrl(Status.SAMPLE_CREATION),
      status: Status.SAMPLE_CREATION,
    },
    {
      id: 'calculation',
      name: convertStatus(Status.CALCULATION),
      workOrders: workOrders?.[Status.CALCULATION],
      link: convertStatusUrl(Status.CALCULATION),
      status: Status.CALCULATION,
    },
    {
      id: 'report',
      name: convertStatus(Status.REPORT),
      workOrders: workOrders?.[Status.REPORT],
      link: convertStatusUrl(Status.REPORT),
      status: Status.REPORT,
    },
    {
      id: 'revision',
      name: convertStatus(Status.REVISION),
      workOrders: workOrders?.[Status.REVISION],
      link: convertStatusUrl(Status.REVISION),
      status: Status.REVISION,
    },
  ];

  useEffect(() => {
    setAllowSearch(true);
  }, [setAllowSearch]);

  return (
    <PipelineContainer>
      {pipelineColumn.map((column) => (
        <Column
          key={column.name}
          columnData={column}
          pipeLoading={pipeLoading}
          isSearching={isSearching}
        />
      ))}
    </PipelineContainer>
  );
}
