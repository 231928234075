/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { PatternFormat } from 'react-number-format';

import {
  ClientKind,
  EvaluationType,
  PropertyType,
  StepTour,
} from '../../api/enumerations';
import {
  CustomTextField,
  NumericTextField,
  SelectTextField,
} from '../../components/CustomInput';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { PeptDialog } from '../../components/Dialog/PeptDialog';
import StandardMap from '../../components/Maps/StandardMap';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { Title } from '../../components/Sections/Title';
import { WorkOrderFile } from '../../components/Sections/WorkOrderFile';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  ButtonsSpace,
  CancelOsBox,
  FlexSpaceBetweenBox,
  SectionBox,
  SubmitBox,
} from '../../components/UI/Box';
import {
  BackButton,
  CancelOSButton,
  FilledButton,
  OutlinedButton,
  RoundedButton,
} from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import { SectionTitle } from '../../components/UI/Typography';
import { Constants } from '../../constants/entrance';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCancelMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPersonMS,
} from '../../constants/icons';
import {
  selectClientKind,
  selectConcept,
  selectConservation,
  selectEvaluationType,
  selectEvaluationTypeBank,
  selectPropertyType,
  selectPropertyUse,
  selectRegistrationUf,
  selectReportFunction,
  selectReportGoal,
  selectStandard,
} from '../../constants/selectOptions';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import useEntrance from './hooks';
import { BoldTypography, CoordGrid, InputBox, StyledTextField } from './styles';

export default function Entrance(): JSX.Element {
  const { navigateHome, osId } = useGeneral();
  const { handleStatus, loadingApprove, setLoadingApprove } = useChangeStatus();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();
  const {
    handleSubmit,
    verifyCep,
    uploadFile,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    setCep,
    cepError,
    city,
    setCity,
    client,
    setClient,
    clientType,
    setClientType,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    evaluationType,
    setEvaluationType,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyType,
    setPropertyType,
    propertyUse,
    setPropertyUse,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    setRequiresInspection,
    rooms,
    setRooms,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    registerFileName,
    iptuFileName,
    createdAt,
    downloadIptu,
    downloadRegister,
    referenceNumber,
    newProperty,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    solicitor,
    setSolicitor,
    propertyData,
    submitLoading,
  } = useEntrance();
  const { setTourOn, driveIsActive, tourCompletion, setTutorialStep } =
    useTour();

  useEffect(() => {
    if (
      !tourCompletion.entrance.complete &&
      !tourCompletion.skipTour.entrance
    ) {
      setTutorialStep(StepTour.ENTRANCESTART);
      setTourOn(true);
    }
    // if (tourSelection && !isTourOn) setTourOn(true);
    setExpandOne(true);
  }, []);

  useEffect(() => {
    if (expandOne && expandTwo) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo]);

  useEffect(() => {
    setExpandOne(false);
    setTimeout(() => {
      setExpandOne(true);
    }, 2000);
  }, []);

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer component="form" id="entrance" onSubmit={handleSubmit}>
        <Title
          osNumber={referenceNumber}
          title={newProperty ? Constants.dataEntrance : Constants.dataEdition}
          newOs={newProperty}
          createdAt={createdAt}
        />
        <FlexSpaceBetweenBox>
          {!newProperty && (
            <CancelOsBox>
              <ConfirmationDialog
                text={Constants.cancelOsText}
                button={
                  <CancelOSButton>
                    {IconCancelMS}
                    {Constants.cancelOs}
                  </CancelOSButton>
                }
                model="error"
                modalCallback={handleCancelWorkOrder}
              />
            </CancelOsBox>
          )}
          <AttachFilesDialog propertyData={propertyData} osId={osId} />
        </FlexSpaceBetweenBox>
        <SectionBox id="report-section">
          <SectionTitle>
            {IconPersonMS}
            {Constants.reportData}
          </SectionTitle>
          <InputBox>
            <CustomTextField
              required
              id="client"
              label="proprietário do imóvel"
              value={client}
              setValue={setClient}
            />
            <SelectTextField
              id="client-type"
              label="tipo do cliente"
              value={clientType}
              setValue={(value) => {
                setClientType(value);
                setEvaluationType(0);
                if (value === ClientKind.BRASIL_BANK) {
                  setSolicitor('Banco do Brasil');
                } else if (value === ClientKind.CAIXA) {
                  setSolicitor('Caixa Econômica');
                } else setSolicitor('');
              }}
              selectOptions={selectClientKind()}
            />
            <SelectTextField
              id="os-goal"
              label="objetivo"
              value={goal}
              setValue={setGoal}
              selectOptions={selectReportGoal()}
            />
          </InputBox>
          <InputBox>
            <CustomTextField
              id="client"
              label="solicitante"
              value={solicitor}
              setValue={setSolicitor}
            />
            <SelectTextField
              id="os-function"
              label="finalidade"
              value={propertyFunction}
              setValue={setPropertyFunction}
              selectOptions={selectReportFunction()}
            />
            <SelectTextField
              id="property-use"
              label="uso do imóvel"
              value={propertyUse}
              setValue={setPropertyUse}
              selectOptions={selectPropertyUse()}
            />
            <SelectTextField
              id="evaluation-type"
              label="tipo do cálculo"
              value={evaluationType}
              setValue={(value) => {
                setEvaluationType(value);
                if (
                  value === EvaluationType.AUTOFACTORS ||
                  value === EvaluationType.AUTOINFERENCES
                ) {
                  setRequiresInspection(false);
                } else {
                  setRequiresInspection(true);
                }
              }}
              selectOptions={
                clientType === ClientKind.BRASIL_BANK ||
                clientType === ClientKind.CAIXA
                  ? selectEvaluationTypeBank()
                  : selectEvaluationType()
              }
            />
          </InputBox>
        </SectionBox>
        <SectionBox>
          <SectionTitle>
            {IconApartmentMS}
            {Constants.propertyData}
          </SectionTitle>
          <WorkOrderFile
            downloadRegister={downloadRegister}
            downloadIptu={downloadIptu}
            registerFileName={registerFileName}
            iptuFileName={iptuFileName}
            handleFileUpload={uploadFile}
          />
          <div id="property-section">
            <InputBox>
              <CustomTextField
                required
                id="register-number"
                label="nº da matrícula"
                value={registerNumber}
                setValue={setRegisterNumber}
              />
              <SelectTextField
                id="property-type"
                label="tipo do imóvel"
                value={propertyType}
                setValue={setPropertyType}
                selectOptions={selectPropertyType()}
              />
              <SelectTextField
                id="concept"
                label="conceito do espaço"
                value={concept}
                setValue={setConcept}
                selectOptions={selectConcept()}
              />
            </InputBox>
            <InputBox>
              <NumericTextField
                id="zone"
                label="ofício/zona"
                suffix=""
                maxLength={12}
                value={zone}
                setValue={setZone}
              />
              <CustomTextField
                required
                id="judicial-district"
                label="comarca"
                value={judicialDistrict}
                setValue={setJudicialDistrict}
              />
              <SelectTextField
                id="registration-uf"
                label="UF"
                value={registrationUf}
                setValue={setRegistrationUf}
                selectOptions={selectRegistrationUf()}
              />
            </InputBox>
          </div>
        </SectionBox>
        <Box>
          <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
          <SectionBox id="location-of-the-property">
            <AccordionTitle
              title={Constants.propertyAddress}
              icon={IconLocationOnMS}
              openAccordion={expandOne}
              setOpenAccordion={setExpandOne}
            />
            {expandOne && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <PatternFormat
                        id="cep"
                        customInput={StyledTextField}
                        label="CEP"
                        required
                        format="#####-###"
                        color="secondary"
                        mask="_"
                        value={cep}
                        onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                          e.target.setCustomValidity('Preencha este campo')
                        }
                        onInput={(e: ChangeEvent<HTMLInputElement>) =>
                          e.target.setCustomValidity('')
                        }
                        error={cepError}
                        onChange={(e) => {
                          setCep(e.target.value);
                          verifyCep(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <CustomTextField
                        required
                        id="address"
                        label="logradouro"
                        value={address}
                        setValue={setAddress}
                        onChange={setIsAddressEdited}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomTextField
                        required
                        id="address-number"
                        label="número"
                        value={addressNumber}
                        setValue={setAddressNumber}
                        onChange={setIsAddressEdited}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <CustomTextField
                        id="complement"
                        label="complemento"
                        value={complement}
                        setValue={setComplement}
                        onChange={setIsAddressEdited}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomTextField
                        required
                        id="district"
                        label="bairro"
                        value={district}
                        setValue={setDistrict}
                        onChange={setIsAddressEdited}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomTextField
                        required
                        id="city"
                        label="cidade"
                        value={city}
                        setValue={setCity}
                        onChange={setIsAddressEdited}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomTextField
                        required
                        id="uf"
                        label="estado"
                        value={uf}
                        setValue={setUf}
                        onChange={setIsAddressEdited}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RoundedButton
                        onClick={() => {
                          setSearchMap(!searchMap);
                          setIsAddressEdited(false);
                        }}
                      >
                        {Constants.searchMap}
                      </RoundedButton>
                    </Grid>
                    <CoordGrid item xs={6}>
                      <BoldTypography>{Constants.lat}</BoldTypography>
                      <Typography>{pinPlace.lat}</Typography>
                    </CoordGrid>
                    <CoordGrid item xs={6}>
                      <BoldTypography>{Constants.lng}</BoldTypography>
                      <Typography>{pinPlace.lng}</Typography>
                    </CoordGrid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <StandardMap
                    searchMap={searchMap}
                    address={`${address} ${addressNumber} ${district} ${city}`}
                    pinPlace={pinPlace}
                    setPinPlace={setPinPlace}
                  />
                </Grid>
              </Grid>
            )}
          </SectionBox>
          <SectionBox id="characteristic-of-the-property">
            <AccordionTitle
              title={Constants.propertyDetails}
              icon={IconLocationCityMS}
              openAccordion={expandTwo}
              setOpenAccordion={setExpandTwo}
            />
            {expandTwo && (
              <>
                <InputBox>
                  <NumericTextField
                    id="total-area"
                    label={
                      propertyType === PropertyType.APARTMENT
                        ? 'área total'
                        : 'área do terreno'
                    }
                    suffix=" m²"
                    decimalSeparator=","
                    decimalScale={2}
                    maxLength={18}
                    value={totalArea}
                    setValue={setTotalArea}
                  />

                  <NumericTextField
                    id="built-area"
                    label={
                      propertyType === PropertyType.APARTMENT
                        ? 'área privativa'
                        : 'área construída'
                    }
                    suffix=" m²"
                    decimalSeparator=","
                    decimalScale={2}
                    maxLength={18}
                    value={builtArea}
                    setValue={setBuiltArea}
                  />
                  <NumericTextField
                    id="rooms"
                    label="quartos"
                    suffix=" quartos"
                    maxLength={12}
                    value={rooms}
                    setValue={setRooms}
                  />
                  <NumericTextField
                    id="toilets"
                    label="banheiros"
                    suffix=" banheiros"
                    maxLength={13}
                    value={toilets}
                    setValue={setToilets}
                  />
                  <NumericTextField
                    id="suites"
                    label="suítes"
                    suffix=" suítes"
                    maxLength={10}
                    value={suites}
                    setValue={setSuites}
                  />
                  <NumericTextField
                    id="parking-lots"
                    label="vagas de garagem"
                    suffix=" vagas"
                    maxLength={9}
                    value={parkingLots}
                    setValue={setParkingLots}
                  />
                </InputBox>
                <InputBox>
                  <NumericTextField
                    id="propertyAge"
                    label="idade do imóvel"
                    suffix=" anos"
                    maxLength={9}
                    value={age}
                    setValue={setAge}
                  />
                  <SelectTextField
                    id="conservation"
                    label="estado de conservação do imóvel"
                    value={conservation}
                    setValue={setConservation}
                    selectOptions={selectConservation()}
                  />
                  <SelectTextField
                    id="building-standard"
                    label="padrão construtivo"
                    value={buildingStandard}
                    setValue={setBuildingStandard}
                    selectOptions={selectStandard()}
                  />
                </InputBox>
              </>
            )}
          </SectionBox>
          <SubmitBox>
            {driveIsActive && newProperty && (
              <PeptDialog osId={osId} referenceNumber={referenceNumber} />
            )}
            {newProperty ? (
              <FilledButton
                id="new-os-button"
                width="lg"
                form="entrance"
                type="submit"
                disabled={submitLoading}
                sx={{
                  marginLeft: 'auto',
                }}
              >
                {submitLoading ? (
                  <CircularProgress size={22} />
                ) : (
                  Constants.submit
                )}
              </FilledButton>
            ) : (
              <>
                <PeptDialog osId={osId} referenceNumber={referenceNumber} />
                <ButtonsSpace>
                  <OutlinedButton
                    width="md"
                    form="entrance"
                    type="submit"
                    disabled={loadingApprove || submitLoading}
                  >
                    {!loadingApprove && submitLoading ? (
                      <CircularProgress size={22} />
                    ) : (
                      Constants.confirmEdit
                    )}
                  </OutlinedButton>
                  <ConfirmationDialog
                    entrance
                    loading={loadingApprove}
                    title={Constants.approve}
                    text={Constants.changeStatus.replace(
                      '**',
                      `${referenceNumber}`
                    )}
                    modalCallback={() => {
                      setLoadingApprove(true);
                      handleStatus(osId, referenceNumber);
                    }}
                  />
                </ButtonsSpace>
              </>
            )}
          </SubmitBox>
        </Box>
      </BoxContainer>
    </GridContainer>
  );
}
