import { Grid } from '@mui/material';
import { Driver } from 'driver.js';
import { useState } from 'react';

import { IconCloseMS } from '../../../constants/icons';
import { updateSkipTour } from '../../../helpers/driver/utils';
import { useTour } from '../../../hooks/useTour';
import {
  CloseButton,
  PageName,
  StyledButton,
  StyledModal,
  SubText,
  Title,
} from '../../Modal/styles';
import { Container, SkipButton } from './styles';

interface UsersTourProps {
  driverObj: Driver | null;
}

export function UsersTour({ driverObj }: UsersTourProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);

  const { setTourOn, setToBeContinued, setTourCompletion, tourCompletion } =
    useTour();

  const handleClose = (): void => {
    updateSkipTour(setTourCompletion, 'users', true);
    setIsOpen(false);
    setTourOn(false);
    setToBeContinued(false);
  };

  const handleSkipTour = (): void => {
    setTourCompletion({
      ...tourCompletion,
      skipTour: {
        onBoarding: true,
        pipeline: true,
        pept: true,
        entrance: true,
        schedule: true,
        inspection: true,
        sampleCreate: true,
        calculation: true,
        report: true,
        revision: true,
        archived: true,
        users: true,
        configuration: true,
        form: true,
        agenda: true,
        errorReport: true,
      },
    });
    handleClose();
  };

  const handleStartDrive = (): void => {
    setIsOpen(false);
    setToBeContinued(true);

    setTimeout(() => {
      if (driverObj) driverObj.drive();
    }, 200);
  };

  return (
    <StyledModal open={isOpen} onClose={handleClose}>
      <Container>
        <SkipButton onClick={handleSkipTour}>pular tour</SkipButton>
        <div className="ponta" />
        <CloseButton onClick={handleClose}>{IconCloseMS}</CloseButton>
        <Grid container direction="column" alignItems="center" marginBottom={4}>
          <Grid item alignItems="center">
            <Title>Vamos dar início ao</Title>
          </Grid>
          <Grid item alignItems="center">
            <PageName>Painel de usuários</PageName>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center" marginBottom={4}>
          <Grid item>
            <SubText>
              Nesta tela você poderá gerenciar os usuários de sua empresa, caso
              tenha autoridade para isso.
            </SubText>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          marginBottom={4}
        >
          <StyledButton onClick={handleStartDrive}>começar!</StyledButton>
        </Grid>
      </Container>
    </StyledModal>
  );
}
