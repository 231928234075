/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { workOrderAPI } from '../../api';
import {
  EvaluationType,
  Status,
  convertArchivedReason,
} from '../../api/enumerations';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import { ExportSample } from '../../components/ExportSample';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { ReportInfo } from '../../components/Sections/ReportInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  FlexSpaceBetweenBox,
  SectionBox,
} from '../../components/UI/Box';
import { BackButton } from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import { InputTitle, StyledInput } from '../../components/UI/Typography';
import { Constants } from '../../constants/archived';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconEngineeringMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { getErrorMessage } from '../../helpers';
import { useAccordion } from '../../hooks/useAccordion';
import useGeneral from '../../hooks/useGeneral';
import { usePropertyData } from '../../hooks/usePropertyData';
import { useRooms } from '../../hooks/useRooms';
import useSampleData from '../../hooks/useSampleData';
import { ButtonBox, ContainedButton } from './styles';

export default function Archived(): JSX.Element {
  const [loadingReport, setLoadingReport] = useState(false);

  const { propertyData, hasAvmReport } = usePropertyData({
    status: Status.ARCHIVED,
  });
  const { sampleData } = useSampleData({ status: Status.ARCHIVED });
  const { osId, navigateHome, handleDownloadPdf } = useGeneral();
  const { rooms } = useRooms();
  const { setSnackbarMessage, setErrorMessage, setOpenSnackbar } =
    useContext(GlobalContext);

  const {
    expandAll,
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    toogleAccordions,
  } = useAccordion();

  const handleExportReport = async (): Promise<void> => {
    setLoadingReport(true);
    try {
      const response = await workOrderAPI.getReportPDF(osId);
      const binaryData = new Blob([response], { type: 'application/pdf' });
      const file = binaryData
        ? new File([binaryData], 'report.pdf', { type: binaryData.type })
        : undefined;

      if (response && file) {
        handleDownloadPdf(file);
        setLoadingReport(false);
      } else {
        throw new Error('Não foi possível fazer o download, tente novamente.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingReport(false);
    }
  };

  const showSchedulingAccordion =
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPINFERENCES;
  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title="arquivado"
          createdAt={propertyData?.created_at}
        />
        <FlexSpaceBetweenBox>
          <Box>
            <InputTitle>motivo:</InputTitle>
            <StyledInput>
              {convertArchivedReason(propertyData?.archived_reason)}
            </StyledInput>
          </Box>
          <AttachFilesDialog propertyData={propertyData} osId={osId} />
        </FlexSpaceBetweenBox>
        <ClientData propertyData={propertyData} />
        <Box>
          <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
          <SectionBox>
            <AccordionTitle
              title="Dados do imóvel"
              icon={IconApartmentMS}
              openAccordion={expandOne}
              setOpenAccordion={setExpandOne}
            />
            {expandOne && <PropertyData propertyData={propertyData} />}
          </SectionBox>
          <SectionBox>
            <AccordionTitle
              title="Localização do imóvel"
              icon={IconLocationOnMS}
              openAccordion={expandTwo}
              setOpenAccordion={setExpandTwo}
            />
            {expandTwo && (
              <PropertyAddressWithMaps
                checkLocation
                propertyData={propertyData}
              />
            )}
          </SectionBox>
          <SectionBox>
            <AccordionTitle
              title="Características do imóvel"
              icon={IconLocationCityMS}
              openAccordion={expandThree}
              setOpenAccordion={setExpandThree}
            />
            {expandThree && <PropertyInfo propertyData={propertyData} />}
          </SectionBox>
          {showSchedulingAccordion && (
            <SectionBox>
              <AccordionTitle
                title="Informações sobre agendamento"
                icon={IconCalendarTodayMS}
                openAccordion={expandFour}
                setOpenAccordion={setExpandFour}
              />
              {expandFour && (
                <Box>
                  {propertyData?.inspection ? (
                    <ScheduleInfo
                      inspectionData={propertyData.inspection}
                      rooms={rooms}
                    />
                  ) : (
                    <Typography>{Constants.noInspection}</Typography>
                  )}
                </Box>
              )}
            </SectionBox>
          )}
          <SectionBox>
            <AccordionTitle
              title="Imagens do imóvel"
              icon={IconPhotoLibraryMS}
              openAccordion={expandFive}
              setOpenAccordion={setExpandFive}
            />
            {expandFive && (
              <Box>
                {propertyData?.inspection && rooms.length > 0 ? (
                  <Rooms
                    navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                    rooms={rooms}
                    osId={osId}
                    inspectionId={propertyData.inspection.id}
                  />
                ) : (
                  <Typography>{Constants.noPhotos}</Typography>
                )}
              </Box>
            )}
          </SectionBox>
          <SectionBox>
            <AccordionTitle
              title="Formulário"
              icon={IconListAltMS}
              openAccordion={expandSix}
              setOpenAccordion={setExpandSix}
            />
            {expandSix && (
              <Box>
                {propertyData?.inspection?.form_response ? (
                  <FormResponse
                    questionForm={propertyData.inspection.form_response}
                  />
                ) : (
                  <Typography>{Constants.noForm}</Typography>
                )}
              </Box>
            )}
          </SectionBox>
          <SectionBox>
            <AccordionTitle
              title="Amostra"
              icon={IconFactCheckMS}
              openAccordion={expandSeven}
              setOpenAccordion={setExpandSeven}
            />
            {expandSeven && (
              <Box>
                {sampleData && sampleData?.length > 0 ? (
                  <SampleSection
                    sampleData={sampleData}
                    propertyData={propertyData}
                  />
                ) : (
                  <Typography>{Constants.noSample}</Typography>
                )}
              </Box>
            )}
          </SectionBox>
          <SectionBox>
            <AccordionTitle
              title="Cálculo automatizado"
              icon={IconEngineeringMS}
              openAccordion={expandEight}
              setOpenAccordion={setExpandEight}
            />
            {expandEight && (
              <Box>
                {hasAvmReport && propertyData ? (
                  <ReportInfo propertyData={propertyData} />
                ) : (
                  <Box>{Constants.noReport}</Box>
                )}
              </Box>
            )}
          </SectionBox>
          <ButtonBox>
            {propertyData?.report_xlsx && (
              <ExportSample
                osId={osId}
                title="exportar lista de elementos e formulário"
              />
            )}
            {propertyData?.report_pdf && (
              <ContainedButton
                onClick={handleExportReport}
                disabled={loadingReport}
              >
                {loadingReport ? (
                  <CircularProgress size={32} />
                ) : (
                  Constants.downloadReport
                )}
              </ContainedButton>
            )}
          </ButtonBox>
        </Box>
      </BoxContainer>
    </GridContainer>
  );
}
