import {
  CustomTextField,
  SelectTextField,
} from '../../../components/CustomInput';
import {
  CloseIcon,
  FilledButton,
  StandardButton,
} from '../../../components/UI/Button';
import { StyledDialog } from '../../../components/UI/Dialog';
import { DialogTitle } from '../../../components/UI/Typography';
import {
  IconAddMS,
  IconCloseMS,
  IconPersonAddMS,
} from '../../../constants/icons';
import { selectProfileUser } from '../../../constants/selectOptions';
import { Constants } from '../../../constants/users';
import useGeneral from '../../../hooks/useGeneral';
import useUsers from '../hooks';
import { ButtonBox, DialogContainer, FormStyled } from './styles';

export interface NewUserDialogProps {
  updateTable(): void;
}

export default function NewUserDialog({
  updateTable,
}: NewUserDialogProps): JSX.Element {
  const dialog = useGeneral();
  const {
    handleNewUser,
    name,
    setName,
    email,
    setEmail,
    selectedRole,
    setSelectedRole,
  } = useUsers();

  const resetInputs = (): void => {
    setName('');
    setEmail('');
    setSelectedRole(0);
  };

  const handleSubmit = (e: React.FormEvent): void => {
    handleNewUser(e).then(() => updateTable());
    resetInputs();
    dialog.handleClose();
  };

  return (
    <div id="new-user-btn">
      <StandardButton onClick={dialog.handleOpen} sx={{ marginRight: '20px' }}>
        {IconAddMS}
        {Constants.buttonAdd}
      </StandardButton>
      <StyledDialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="upload logo"
      >
        <DialogContainer>
          <CloseIcon onClick={dialog.handleClose}>{IconCloseMS}</CloseIcon>
          <DialogTitle>
            {IconPersonAddMS}
            {Constants.buttonAdd}
          </DialogTitle>
          <FormStyled component="form" id="login" onSubmit={handleSubmit}>
            <CustomTextField
              required
              id="user-name"
              label={Constants.name}
              value={name}
              setValue={setName}
            />
            <CustomTextField
              required
              id="user-email"
              label={Constants.email}
              value={email}
              setValue={setEmail}
            />
            <SelectTextField
              id="user-role"
              label={Constants.role}
              required
              value={selectedRole}
              setValue={setSelectedRole}
              selectOptions={selectProfileUser()}
            />
            <ButtonBox>
              <FilledButton form="login" type="submit">
                {Constants.buttonAdd}
              </FilledButton>
            </ButtonBox>
          </FormStyled>
        </DialogContainer>
      </StyledDialog>
    </div>
  );
}
