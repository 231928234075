import { handleUrl, myHeadersWithoutToken } from '../global';
import { DetailProps, PotencialCustomerData } from './types';

const registerPotencialCustomer = (
  data: PotencialCustomerData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithoutToken(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/potential-customers`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  registerPotencialCustomer,
};
