/* eslint-disable react-hooks/exhaustive-deps */
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Box, LinearProgress } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { workOrderAPI } from '../../../api';
import { IconDownloadMS, IconTableViewMS } from '../../../constants/icons';
import { Constants } from '../../../constants/revision';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import { useTour } from '../../../hooks/useTour';
import {
  ContainerButtons,
  DownloadButton,
  ExportButton,
  Iframe,
} from './styles';

interface PdfViewerProps {
  osId: number;
}

export function PdfViewer({ osId }: PdfViewerProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState<File>();
  const [pdfURL, setPdfURL] = useState('');

  const { setOpenSnackbar, setSnackbarMessage, setErrorMessage } =
    useContext(GlobalContext);

  const { setEnableTour } = useTour();

  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadPdf, handleDownloadXlsx } = useGeneral();

  const getPDF = useCallback(async () => {
    setLoading(true);
    try {
      const response = await workOrderAPI.getReportPDF(osId);
      const binaryData = new Blob([response], { type: 'application/pdf' });
      const url = URL.createObjectURL(binaryData);
      const file = binaryData
        ? new File([binaryData], 'report.pdf', { type: binaryData.type })
        : undefined;
      setPdfURL(url);
      setEnableTour(file !== undefined);
      setPdfFile(file);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [osId, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  useEffect(() => {
    getPDF();
  }, []);

  const handleExportSample = async (): Promise<void> => {
    try {
      const response = await workOrderAPI.exportSample(osId);

      if (response.ok) {
        handleDownloadXlsx(response);
      } else {
        throw new Error('Não foi possível fazer o download, tente novamente.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  if (loading) return <LinearProgress />;

  return (
    <Box>
      {!pdfFile ? (
        <p>não foi possível carregar o relatório</p>
      ) : (
        <>
          <Iframe src={pdfURL} title="relatório" />
          <ContainerButtons>
            <ExportButton id="xlsx-button" onClick={handleExportSample}>
              {IconTableViewMS}
              {Constants.downloadExcel}
            </ExportButton>
            <DownloadButton
              id="report-pdf-button"
              disabled={!pdfFile}
              onClick={() => handleDownloadPdf(pdfFile)}
            >
              {IconDownloadMS}
              {Constants.download}
            </DownloadButton>
          </ContainerButtons>
        </>
      )}
    </Box>
  );
}
