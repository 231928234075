import { Grid } from '@mui/material';
import { useContext, useState } from 'react';

import { inspectionAPI } from '../../../api';
import { QuestionType, StatusCode } from '../../../api/enumerations';
import {
  QuestionFormData,
  QuestionResponseData,
  UpdateQuestionData,
} from '../../../api/workOrders/types';
import {
  IconKeyboardDoubleArrowLeftMS,
  IconKeyboardDoubleArrowRightMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/inspection';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { Question } from './Question';
import { QuestionItem } from './QuestionItem';
import {
  NavigationBox,
  OutlinedButton,
  PaperQuestions,
  QuestionsBox,
  SelectTypography,
  StyledProgress,
} from './styles';

interface InspectionFormProps {
  inspectionId: number;
  engineerId: number | null;
  questionForm: QuestionResponseData;
  progress: number;
  handleProgress: (list: QuestionFormData[]) => void;
}

export function InspectionForm({
  inspectionId,
  engineerId,
  questionForm,
  progress,
  handleProgress,
}: InspectionFormProps): JSX.Element {
  const [questionData, setQuestionData] = useState<QuestionFormData>();
  const [questionNum, setQuestionNum] = useState(0);
  const [answer, setAnswer] = useState<string | number>('');
  const [checkboxAnswer, setCheckboxAnswer] = useState<number[]>([]);
  const [saveAnswer, setSaveAnswer] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const navigateNext = (): void => {
    if (!questionForm) {
      return;
    }
    if (questionForm.json.length === questionNum) {
      setQuestionData(questionForm.json[0]);
      setQuestionNum(1);
      return;
    }
    setQuestionData(questionForm.json[questionNum]);
    setQuestionNum(questionNum + 1);
  };

  const navigateLast = (): void => {
    if (!questionForm) {
      return;
    }
    if (questionNum === 1) {
      const totalQ = questionForm.json.length;
      setQuestionData(questionForm.json[totalQ - 1]);
      setQuestionNum(totalQ);
      return;
    }
    setQuestionData(questionForm.json[questionNum - 2]);
    setQuestionNum(questionNum - 1);
  };

  const handleAnswer = async (): Promise<void> => {
    if (!questionData) {
      setSnackbarMessage('Algo deu errado, tente novamente.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSaveAnswer(false);
      return;
    }
    if (questionData.type === QuestionType.MULTIPLE_CHOICE) {
      if (checkboxAnswer.length < 1) {
        setSnackbarMessage('Selecione ao menos uma opção.');
        setErrorMessage(true);
        setOpenSnackbar(true);
        setSaveAnswer(false);
        return;
      }
    } else if (!answer) {
      setSnackbarMessage('Preencha a resposta.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSaveAnswer(false);
      return;
    }

    const editQuestion = questionForm.json.find((element) => {
      return element.id === questionData.id;
    });

    if (!editQuestion) {
      setSnackbarMessage('Algo deu errado, tente novamente.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSaveAnswer(false);
      return;
    }

    if (questionData.type === QuestionType.MULTIPLE_CHOICE) {
      editQuestion.answer = checkboxAnswer;
    } else {
      editQuestion.answer = answer;
    }

    const inspectionData: UpdateQuestionData = {
      form_response: JSON.stringify(questionForm),
    };

    if (engineerId !== null) inspectionData.engineer_id = engineerId;

    try {
      const response = await inspectionAPI.updateInspections(
        inspectionId,
        inspectionData
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleProgress(questionForm.json);
      setSnackbarMessage('Resposta salva!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setSaveAnswer(false);

      const hasJumpTo = questionData.choices
        .filter((item) => item.jump_to !== null)
        .find((data) => data.id === questionData.answer);

      if (hasJumpTo) {
        const goalQuestion = questionForm.json.find(
          (data) => data.id === hasJumpTo.jump_to
        );

        if (goalQuestion) {
          const questionInd = questionForm.json.indexOf(goalQuestion);
          setQuestionData(questionForm.json[questionInd]);
          setQuestionNum(questionInd + 1);
        }
      } else {
        const qtdOfQuestions = questionForm.json.length;

        const currentQuestion = questionForm.json.find(
          (state) => state.title === questionData.title
        );

        if (currentQuestion !== undefined) {
          const questionIndex = questionForm.json.indexOf(currentQuestion);

          if (questionIndex + 1 <= qtdOfQuestions) {
            setQuestionData(questionForm.json[questionIndex + 1]);
            setQuestionNum(questionIndex + 2);
          }
        }
      }
    } catch (error) {
      editQuestion.answer = 0;
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSaveAnswer(false);
    }
  };

  const checkLastAnswerToSave = (changeQuestion: 'next' | 'last'): void => {
    const handleNavigation = (): void => {
      if (changeQuestion === 'next') {
        navigateNext();
      } else if (changeQuestion === 'last') {
        navigateLast();
      }
    };

    if (saveAnswer) {
      handleAnswer().then(handleNavigation);
    } else {
      handleNavigation();
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <StyledProgress variant="determinate" value={progress} />
      </Grid>
      <Grid item xs={4}>
        <PaperQuestions>
          {questionForm.json &&
            questionForm?.json.map((data, i) => (
              <QuestionsBox
                key={data.id}
                open={questionNum === i + 1}
                onClick={() => {
                  if (saveAnswer) {
                    handleAnswer().then(() => {
                      setQuestionData(data);
                      setQuestionNum(i + 1);
                    });
                  } else {
                    setQuestionData(data);
                    setQuestionNum(i + 1);
                  }
                }}
              >
                <QuestionItem
                  title={data.title}
                  questionNum={i + 1}
                  checkAnswer={!!(data.answer && data.answer !== null)}
                />
              </QuestionsBox>
            ))}
        </PaperQuestions>
      </Grid>
      <Grid item xs={8}>
        {questionData ? (
          <>
            <Question
              question={questionData}
              questionNum={questionNum}
              answer={answer}
              setAnswer={setAnswer}
              checkboxAnswer={checkboxAnswer}
              setCheckboxAnswer={setCheckboxAnswer}
              setSaveAnswer={setSaveAnswer}
              handleAnswer={handleAnswer}
            />
            <NavigationBox>
              <OutlinedButton onClick={() => checkLastAnswerToSave('next')}>
                {IconKeyboardDoubleArrowRightMS}
              </OutlinedButton>
              <OutlinedButton onClick={() => checkLastAnswerToSave('last')}>
                {IconKeyboardDoubleArrowLeftMS}
              </OutlinedButton>
            </NavigationBox>
          </>
        ) : (
          <SelectTypography>{Constants.selectQuestion}</SelectTypography>
        )}
      </Grid>
    </Grid>
  );
}
