/* eslint-disable max-len */
import { Driver } from 'driver.js';

import { InitialModalTour } from '../../Modal/InitialModalTour';

interface EntranceTourProps {
  driverObj: Driver | null;
}

export function EntranceTour({ driverObj }: EntranceTourProps): JSX.Element {
  return (
    <InitialModalTour
      title="Vamos dar inicio a"
      pageName="entrada de dados"
      subtext1="Nessa tela serão inseridas as informações referentes ao cliente, imóvel avaliando e tipo de relatório. Essas informações são importantes para seguir o fluxo de atendimento da Ordem de Serviço"
      subtext2=""
      driverObj={driverObj && driverObj}
      stepTourName="entrance"
    />
  );
}
