/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, Pagination } from '@mui/material';
import { useContext, useEffect } from 'react';

import { StepTour } from '../../api/enumerations';
import { UserData } from '../../api/users/types';
import SearchBox from '../../components/SearchBox';
import { PagesContainer, TitleBox } from '../../components/UI/Box';
import { TitleTypography } from '../../components/UI/Typography';
import { Constants } from '../../constants/users';
import { GlobalContext } from '../../context/global';
import { useTour } from '../../hooks/useTour';
import NewUserDialog from './Dialog/NewUser';
import useUsers from './hooks';
import { FilterBox, LoadingBox, TableBox } from './styles';
import TableUser from './Table';

export default function Users(): JSX.Element {
  const {
    setLoading,
    loading,
    users,
    searchTerm,
    setSearchTerm,
    searchedUsers,
    setSearchedUsers,
    page,
    setPage,
    totalPages,
    handleNextPage,
  } = useUsers();
  const { company } = useContext(GlobalContext);

  const updateUsers = (): void => {
    setPage(1);
    setLoading(true);
  };

  useEffect(() => {
    updateUsers();
  }, [company]);

  const { tourCompletion, setTutorialStep, setTourOn } = useTour();

  useEffect(() => {
    if (!tourCompletion.users.complete && !tourCompletion.skipTour.users) {
      setTutorialStep(StepTour.USERSSTART);
      setTourOn(true);
    }
  }, []);

  return (
    <>
      <TitleBox>
        <TitleTypography>{Constants.panel}</TitleTypography>
        <NewUserDialog updateTable={updateUsers} />
      </TitleBox>
      <TableBox>
        <FilterBox>
          <SearchBox<UserData>
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            initialDatas={users}
            setResults={setSearchedUsers}
          />
        </FilterBox>
        {loading ? (
          <LoadingBox>
            <LinearProgress />
          </LoadingBox>
        ) : (
          <>
            <TableUser
              updateTable={updateUsers}
              tableData={searchedUsers.length !== 0 ? searchedUsers : users}
            />
            <PagesContainer>
              <Pagination
                page={page}
                count={
                  searchedUsers.length !== 0
                    ? Math.ceil(Number(searchedUsers.length) / 10)
                    : totalPages
                }
                onChange={(_, page) => handleNextPage(page)}
              />
            </PagesContainer>
          </>
        )}
      </TableBox>
    </>
  );
}
