/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { loginAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import {
  getLocalStorageToken,
  setLocalStorageToken,
} from '../../../helpers/localStorage';
import useGeneral from '../../../hooks/useGeneral';

interface LoginHook {
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  showPassword: boolean;
  setShowPassword: (value: boolean) => void;
  submitLoading: boolean;
}

const useLogin = (): LoginHook => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { navigateHome } = useGeneral();
  const token = getLocalStorageToken();

  useEffect(() => {
    if (token !== null && token !== 'undefined') {
      navigateHome();
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();
    try {
      setSubmitLoading(true);
      const response = await loginAPI.login(trimEmail, password);

      if (response.detail.status_code === StatusCode.ERROR) {
        throw new Error('Email ou senha inválidos');
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.access_token && response.user) {
        if (response.user.companies.length === 1) {
          // users with subdomain realprice-st must be redirected
          const isRealPrice = response.user.companies.find(
            (element) =>
              element.subdomain === 'realprice-st' ||
              element.subdomain === 'realprice'
          );
          if (isRealPrice) {
            setSnackbarMessage('Continue seu login na Real Price.');
            setErrorMessage(false);
            setOpenSnackbar(true);
            const link = document.createElement('a');
            link.href = 'http://realprice.protoai.com.br';
            setTimeout(() => {
              link.click();
            }, 2000);
            return;
          }
        }

        const item = response.access_token;
        setLocalStorageToken(item);
        setSubmitLoading(false);
        navigateHome();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  return {
    handleSubmit,
    email,
    setEmail,
    password,
    setPassword,
    showPassword,
    setShowPassword,
    submitLoading,
  };
};

export default useLogin;
