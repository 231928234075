import { Box, TextField, Typography, styled, Button } from '@mui/material';

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F5F5F4',
  boxShadow: '0px 1px 2px #61335429',
  width: '70%',
  maxWidth: 1300,
  padding: '90px 0',
}));

export const StyledText = styled(Typography)(() => ({
  fontSize: 24,
  marginBottom: 60,
  textAlign: 'justify',
}));

export const EmailInput = styled(TextField)(() => ({
  marginTop: 30,
  marginBottom: 16,
  width: 600,
  height: 56,
  '& .MuiInputBase-root': {
    borderRadius: 16,
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  backgroundColor: '#613354',
  fontWeight: '700',
  color: '#D6D4D0',
  textTransform: 'uppercase',
  margin: 'clamp(42px, 6.7vh ,62px) 0',
  boxShadow: '0px 3px 15px #00000029',
  '&:hover': {
    backgroundColor: '#9B7359',
  },
  [theme.breakpoints.down('md')]: {
    width: '280px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
  },
}));
