export const Constants = {
  revision: 'revisão e aprovação',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  propertyData: 'Dados do imóvel',
  report: 'Relatório',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  noInspection: 'esta OS não possui agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  form: 'Formulário',
  noForm: 'esta OS não possui formulário',
  sample: 'Amostra',
  calculation: 'Cálculo automatizado',
  noReport: 'Relatório não pode ser criado',
  reject: 'rejeitar',
  approve: 'aprovar',
  sendToClient: 'Enviar para cliente automaticamente',
  changeStatus:
    'alterar o status da OS ** de Revisão e Aprovação para Arquivado',
  download: 'download',
  downloadExcel: 'Exportar dados do cálculo e formulário',
  errorReport: 'reportar erro',
  noSample: 'esta OS não possui amostra',
};
