/* eslint-disable react/require-default-props */
/* eslint-disable react/require-default-props */
import { Grid } from '@mui/material';
import { Driver } from 'driver.js';
import { useState } from 'react';

import { TourData } from '../../api/users/types';
import { IconCloseMS } from '../../constants/icons';
import { updateSkipTour } from '../../helpers/driver/utils';
import { useTour } from '../../hooks/useTour';
import {
  CloseButton,
  Container,
  PageName,
  SkipTourButton,
  StyledButton,
  StyledModal,
  SubText,
  Title,
} from './styles';

interface InicialModalTourProps {
  title: string;
  pageName: string;
  subtext1: string;
  subtext2?: string;
  // eslint-disable-next-line
  driverObj?: Driver | null;
  stepTourName: keyof TourData['skipTour'];
}

export function InitialModalTour({
  title,
  pageName,
  subtext1,
  subtext2 = '',
  driverObj,
  stepTourName,
}: InicialModalTourProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);

  const {
    setDriveIsActive,
    setToBeContinued,
    setTourOn,
    setTourSelection,
    setTourCompletion,
    tourCompletion,
  } = useTour();

  const handleClose = (): void => {
    setToBeContinued(false);
    setTourSelection(false);
    setDriveIsActive(false);
    setIsOpen(false);
    setTourOn(false);

    updateSkipTour(setTourCompletion, stepTourName, true);
  };

  const handleSkipTour = (): void => {
    setTourCompletion({
      ...tourCompletion,
      skipTour: {
        onBoarding: true,
        pipeline: true,
        pept: true,
        entrance: true,
        schedule: true,
        inspection: true,
        sampleCreate: true,
        calculation: true,
        report: true,
        revision: true,
        archived: true,
        users: true,
        configuration: true,
        form: true,
        agenda: true,
        errorReport: true,
      },
    });
    handleClose();
  };

  const handleStartDrive = (): void => {
    setDriveIsActive(true);
    setToBeContinued(true);
    setIsOpen(false);
    setTourOn(false);

    setTimeout(() => {
      driverObj?.drive();
    }, 300);
  };

  return (
    <StyledModal open={isOpen} onClose={handleClose}>
      <Container>
        <SkipTourButton type="button" onClick={handleSkipTour}>
          pular tour
        </SkipTourButton>
        <CloseButton onClick={handleClose}>{IconCloseMS}</CloseButton>
        <Grid container direction="column" alignItems="center" marginBottom={4}>
          <Grid item alignItems="center">
            <Title>{title}</Title>
          </Grid>
          <Grid item alignItems="center">
            <PageName>{pageName}</PageName>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center" marginBottom={4}>
          <Grid item>
            <SubText>{subtext1}</SubText>
          </Grid>
          {subtext2 && (
            <Grid item>
              <SubText>{subtext2}</SubText>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          marginBottom={4}
        >
          <StyledButton onClick={handleStartDrive}>começar!</StyledButton>
        </Grid>
      </Container>
    </StyledModal>
  );
}
