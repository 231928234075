/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Driver, driver } from 'driver.js';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Status, StepTour } from '../api/enumerations';
import { PutGuideTour } from '../api/users';
import { TourData } from '../api/users/types';
import { WorkOrderData } from '../api/workOrders/types';
import { homeTutorial } from '../components/Tutorial';
import { driverConfig } from '../helpers/driver/config';
import * as step from '../helpers/driver/steps';
import tourInitialState from '../helpers/driver/tourInitialState';
import { useStoragedJwt } from './useDecodedJwt';
import { useWorkOrder } from './useWorkOrder';

type TutorialStep = React.FC<{
  driverObj: Driver | null;
  setTutorialStep: React.Dispatch<React.SetStateAction<number>>;
  setProgressOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTourOn: React.Dispatch<React.SetStateAction<boolean>>;
}>;

interface ContextProps {
  enableTour: boolean;
  setEnableTour: React.Dispatch<React.SetStateAction<boolean>>;
  tutorialStep: number;
  setTutorialStep: React.Dispatch<React.SetStateAction<number>>;
  isTourOn: boolean;
  setTourOn: React.Dispatch<React.SetStateAction<boolean>>;
  isProgressOpen: boolean;
  setProgressOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toBeContinued: boolean;
  setToBeContinued: React.Dispatch<React.SetStateAction<boolean>>;
  tourCompletion: TourData;
  setTourCompletion: React.Dispatch<React.SetStateAction<TourData>>;
  tourPercentage: number;
  tourSelection: boolean;
  setTourSelection: React.Dispatch<React.SetStateAction<boolean>>;
  driveIsActive: boolean;
  setDriveIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  peptWorkOrders: WorkOrderData[];
  schedulingWorkOrders: WorkOrderData[];
  inspectionWorkOrders: WorkOrderData[];
  sampleCreationWorkOrders: WorkOrderData[];
  calculationWorkOrders: WorkOrderData[];
  reportWorkOrders: WorkOrderData[];
  revisionWorkOrders: WorkOrderData[];
}

const TourContext = createContext({} as ContextProps);

export function TourProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [tutorialStep, setTutorialStep] = useState(0);
  const [isTourOn, setTourOn] = useState(false);
  const [isProgressOpen, setProgressOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [toBeContinued, setToBeContinued] = useState(false);
  const [tourSelection, setTourSelection] = useState(false);
  const [driveIsActive, setDriveIsActive] = useState(false);
  const [tourCompletion, setTourCompletion] =
    useState<TourData>(tourInitialState);

  const [enableTour, setEnableTour] = useState(false);

  const navigate = useNavigate();

  const decoded = useStoragedJwt();
  const { workOrders } = useWorkOrder();

  const peptWorkOrders = useMemo(() => {
    return workOrders?.[Status.PEPT] || [];
  }, [workOrders]);

  const schedulingWorkOrders = useMemo(() => {
    return workOrders?.[Status.SCHEDULE] || [];
  }, [workOrders]);

  const inspectionWorkOrders = useMemo(() => {
    return workOrders?.[Status.INSPECTION] || [];
  }, [workOrders]);

  const sampleCreationWorkOrders = useMemo(() => {
    return workOrders?.[Status.SAMPLE_CREATION] || [];
  }, [workOrders]);

  const calculationWorkOrders = useMemo(() => {
    return workOrders?.[Status.CALCULATION] || [];
  }, [workOrders]);

  const reportWorkOrders = useMemo(() => {
    return workOrders?.[Status.REPORT] || [];
  }, [workOrders]);

  const revisionWorkOrders = useMemo(() => {
    return workOrders?.[Status.REVISION] || [];
  }, [workOrders]);

  const updateGuideTour = async (): Promise<void> => {
    const guide_tour = JSON.stringify(tourCompletion);

    if (decoded) {
      const response = await PutGuideTour(decoded?.user.id, guide_tour);
      if (response.detail?.description) {
        // eslint-disable-next-line no-console
        console.log(response.detail.description);
      }
    }
  };

  const addMissingSteps = (tour: any): void => {
    const copyTourCompletion = tour;
    const copyInitialState: any = tourInitialState;

    Object.keys(copyInitialState).forEach((newKey) => {
      if (!(newKey in tour)) {
        copyTourCompletion[newKey] = copyInitialState[newKey];
      }
    });
    return copyTourCompletion;
  };

  const tourPercentage = useMemo(() => {
    const otherValues = Object.values(tourCompletion).map(
      (tour) => tour.complete
    );
    const inspectionValues = Object.values(tourCompletion.inspection).map(
      (i) => i.complete
    );
    const sampleValues = Object.values(tourCompletion.sampleCreate).map(
      (s) => s.complete
    );

    const combinedValues = otherValues
      .concat(inspectionValues)
      .concat(sampleValues);
    const finishedTours = combinedValues.filter((v) => v);
    const percentage = (finishedTours.length * 100) / combinedValues.length;

    return Math.round(percentage);
  }, [tourCompletion]);

  const driverObj: Driver | null = useMemo(() => {
    switch (tutorialStep) {
      case StepTour.GUIDEDTOUR:
        return driver({
          ...driverConfig,
          steps: step.onBoardingSteps,
          onPrevClick: () => {
            const activeIndex = driverObj?.getActiveIndex();
            if (activeIndex === 2) {
              setProgressOpen(false);
            }
            driverObj?.movePrevious();
          },
          onNextClick: () => {
            const activeIndex = driverObj?.getActiveIndex() || 0;
            if (activeIndex === 1) {
              setProgressOpen(true);
              setTimeout(() => {
                driverObj?.moveTo(2);
              }, 200);
            }
            if (driverObj?.isLastStep()) {
              setTourCompletion({
                ...tourCompletion,
                onBoarding: {
                  complete: true,
                },
                lastStepSeen: StepTour.GUIDEDTOUR,
              });
              setProgressOpen(false);
              setTourOn(true);
              driverObj?.destroy();
              setTutorialStep(StepTour.PIPELINESTART);
            }
            driverObj?.moveNext();
          },
          onCloseClick: () => {
            setTourCompletion({
              ...tourCompletion,
              onBoarding: { complete: false },
              lastStepSeen: StepTour.GUIDEDTOUR - 1,
            });
            setTourSelection(false);
            setToBeContinued(false);
            setTourOn(false);
            driverObj?.destroy();
          },
          onDestroyStarted: () => {
            if (driverObj?.hasNextStep()) {
              setTourSelection(false);
              setToBeContinued(false);
              setTourOn(false);
            } else if (driverObj?.isLastStep()) {
              return;
            } else {
              setTourSelection(false);
              setToBeContinued(false);
            }
            driverObj?.destroy();
          },
        });
      case StepTour.PIPELINESTART:
        return driver({
          ...driverConfig,
          steps: step.pipelineSteps,
          onNextClick: () => {
            if (driverObj?.isLastStep()) {
              setTourCompletion({
                ...tourCompletion,
                pipeline: { complete: true },
                lastStepSeen: StepTour.PIPELINEFINISH,
              });
              setTutorialStep(StepTour.PIPELINEFINISH);
              driverObj?.destroy();
              setTimeout(() => {
                setTourOn(true);
              }, 500);
            }
            driverObj?.moveNext();
          },
          onCloseClick: () => {
            setTourSelection(false);
            setToBeContinued(false);
            setTourCompletion({
              ...tourCompletion,
              lastStepSeen: StepTour.PIPELINESTART - 1,
            });
            setTourOn(false);
            driverObj?.destroy();
          },
          onDestroyStarted: () => {
            if (driverObj?.hasNextStep()) {
              setTourSelection(false);
              setToBeContinued(false);
              setTourOn(false);
            } else if (driverObj?.isLastStep()) {
              return;
            } else {
              setTourSelection(false);
              setToBeContinued(false);
            }
            driverObj?.destroy();
          },
        });
      case StepTour.ENTRANCESTART:
        return driver({
          ...driverConfig,
          steps: step.entranceSteps,
          onNextClick: () => {
            if (driverObj?.isLastStep()) {
              setTourCompletion({
                ...tourCompletion,
                entrance: { complete: true },
                lastStepSeen: StepTour.ENTRANCEFINISH,
              });
              setTutorialStep(StepTour.ENTRANCEFINISH);
              driverObj?.destroy();
              setTimeout(() => {
                setDriveIsActive(false);
                setTourOn(true);
                setTourSelection(false);
                setToBeContinued(false);
              }, 500);
            }
            driverObj?.moveNext();
          },
          onCloseClick: () => {
            driverObj?.destroy();
            setTourOn(false);
            setTourSelection(false);
            setToBeContinued(false);
            setDriveIsActive(false);
            setTourCompletion({
              ...tourCompletion,
              lastStepSeen: StepTour.ENTRANCESTART - 1,
            });
            setTourOn(false);
            driverObj?.destroy();
          },
          onDestroyStarted: () => {
            if (driverObj?.hasNextStep()) {
              setTourSelection(false);
              setToBeContinued(false);
              setTourOn(false);
            } else if (driverObj?.isLastStep()) {
              return;
            } else {
              setTourSelection(false);
              setToBeContinued(false);
            }
            driverObj?.destroy();
          },
        });
      case StepTour.ARCHIVEDSTART:
        return driver({
          ...driverConfig,
          steps: step.archivedSteps,
          onNextClick: () => {
            if (driverObj?.isLastStep()) {
              setTourCompletion({
                ...tourCompletion,
                archived: { complete: true },
                lastStepSeen: StepTour.ARCHIVEDFINISH,
              });
              setTutorialStep(StepTour.ARCHIVEDFINISH);
              driverObj?.destroy();
            }
            driverObj?.moveNext();
          },
          onCloseClick: () => {
            setTourSelection(false);
            setToBeContinued(false);
            setTourCompletion({
              ...tourCompletion,
              lastStepSeen: StepTour.ARCHIVEDSTART - 1,
            });
            setTourOn(false);
            driverObj?.destroy();
          },
          onDestroyStarted: () => {
            if (driverObj?.hasNextStep()) {
              setTourSelection(false);
              setToBeContinued(false);
              setTourOn(false);
            } else if (driverObj?.isLastStep()) {
              return;
            } else {
              setTourSelection(false);
              setToBeContinued(false);
            }
            driverObj?.destroy();
          },
        });
      case StepTour.USERSSTART:
        return driver({
          ...driverConfig,
          steps: step.usersSteps,
          onNextClick: () => {
            if (driverObj?.isLastStep()) {
              setTourCompletion({
                ...tourCompletion,
                users: { complete: true },
                lastStepSeen: StepTour.USERSFINISH,
              });
              setTutorialStep(StepTour.USERSFINISH);
              driverObj?.destroy();
            }
            driverObj?.moveNext();
          },
          onCloseClick: () => {
            setTourSelection(false);
            setToBeContinued(false);
            setTourCompletion({
              ...tourCompletion,
              lastStepSeen: StepTour.USERSSTART - 1,
            });
            setTourOn(false);
            driverObj?.destroy();
          },
          onDestroyStarted: () => {
            if (driverObj?.hasNextStep()) {
              setTourSelection(false);
              setToBeContinued(false);
              setTourOn(false);
            } else if (driverObj?.isLastStep()) {
              return;
            } else {
              setTourSelection(false);
              setToBeContinued(false);
            }
            driverObj?.destroy();
          },
        });
      case StepTour.FORMSTART:
        return driver({
          ...driverConfig,
          steps: step.formSteps,
          onPrevClick: () => {
            const activeIndex = driverObj?.getActiveIndex();
            if (activeIndex === 2) {
              navigate('/forms');
              setTimeout(() => {
                driverObj?.moveTo(1);
              }, 100);
            }
            driverObj?.movePrevious();
          },
          onNextClick: () => {
            const activeIndex = driverObj?.getActiveIndex();
            if (activeIndex === 1) {
              navigate('/forms/new');
              setTimeout(() => {
                driverObj?.moveTo(2);
              }, 100);
            }
            if (driverObj?.isLastStep()) {
              setTourCompletion({
                ...tourCompletion,
                form: { complete: true },
                lastStepSeen: StepTour.FORMFINISH,
              });
              setTutorialStep(StepTour.FORMFINISH);
              driverObj?.destroy();
            }
            driverObj?.moveNext();
          },
          onCloseClick: () => {
            setTourSelection(false);
            setToBeContinued(false);
            setTourCompletion({
              ...tourCompletion,
              lastStepSeen: StepTour.FORMSTART - 1,
            });
            setTourOn(false);
            driverObj?.destroy();
          },
          onDestroyStarted: () => {
            if (driverObj?.hasNextStep()) {
              setTourSelection(false);
              setToBeContinued(false);
              setTourOn(false);
            } else if (driverObj?.isLastStep()) {
              return;
            } else {
              setTourSelection(false);
              setToBeContinued(false);
            }
            driverObj?.destroy();
          },
        });
      case StepTour.AGENDASTART:
        return driver({
          ...driverConfig,
          steps: step.agendaSteps,
          onNextClick: () => {
            if (driverObj?.isLastStep()) {
              setTourCompletion({
                ...tourCompletion,
                agenda: { complete: true },
                lastStepSeen: StepTour.AGENDAFINISH,
              });
              setTutorialStep(StepTour.AGENDAFINISH);
              driverObj?.destroy();
            }
            driverObj?.moveNext();
          },
          onCloseClick: () => {
            setTourSelection(false);
            setToBeContinued(false);
            setTourOn(false);
            driverObj?.destroy();
          },
          onDestroyStarted: () => {
            if (driverObj?.hasNextStep()) {
              setTourSelection(false);
              setToBeContinued(false);
              setTourOn(false);
            } else if (driverObj?.isLastStep()) {
              return;
            } else {
              setTourSelection(false);
              setToBeContinued(false);
            }
            driverObj?.destroy();
          },
        });
      case StepTour.ERRORREPORTSTART:
        return driver({
          ...driverConfig,
          steps: step.errorReportSteps,
          onNextClick: () => {
            if (driverObj?.isLastStep()) {
              setTourCompletion({
                ...tourCompletion,
                errorReport: { complete: true },
                lastStepSeen: StepTour.ERRORREPORTFINISH,
              });
              driverObj.destroy();
              setTutorialStep(StepTour.ERRORREPORTFINISH);
            }
            driverObj?.moveNext();
          },
          onCloseClick: () => {
            setTourSelection(false);
            setToBeContinued(false);
            setTourOn(false);
            driverObj?.destroy();
          },
          onDestroyStarted: () => {
            setTourSelection(false);
            setToBeContinued(false);
            setTourOn(false);
            driverObj?.destroy();
          },
        });
      default:
        return null;
    }
  }, [tutorialStep, tourCompletion, navigate]);

  const CurrentStep: TutorialStep = homeTutorial[tutorialStep];

  const context: ContextProps = useMemo(
    () => ({
      enableTour,
      setEnableTour,
      tutorialStep,
      setTutorialStep,
      isTourOn,
      setTourOn,
      isProgressOpen,
      setProgressOpen,
      drawerOpen,
      setDrawerOpen,
      toBeContinued,
      setToBeContinued,
      tourCompletion,
      setTourCompletion,
      tourPercentage,
      tourSelection,
      setTourSelection,
      driveIsActive,
      setDriveIsActive,
      peptWorkOrders,
      schedulingWorkOrders,
      inspectionWorkOrders,
      sampleCreationWorkOrders,
      calculationWorkOrders,
      reportWorkOrders,
      revisionWorkOrders,
    }),
    [
      enableTour,
      setEnableTour,
      tutorialStep,
      setTutorialStep,
      isTourOn,
      setTourOn,
      isProgressOpen,
      setProgressOpen,
      drawerOpen,
      setDrawerOpen,
      toBeContinued,
      setToBeContinued,
      tourCompletion,
      setTourCompletion,
      tourPercentage,
      tourSelection,
      setTourSelection,
      driveIsActive,
      setDriveIsActive,
      peptWorkOrders,
      schedulingWorkOrders,
      inspectionWorkOrders,
      sampleCreationWorkOrders,
      calculationWorkOrders,
      reportWorkOrders,
      revisionWorkOrders,
    ]
  );

  useEffect(() => {
    addMissingSteps(tourCompletion);
    if (tourCompletion !== tourInitialState) updateGuideTour();
  }, [tourCompletion]);

  return (
    <TourContext.Provider value={context}>
      {isTourOn && (
        <CurrentStep
          driverObj={driverObj}
          setTutorialStep={setTutorialStep}
          setProgressOpen={setProgressOpen}
          setTourOn={setTourOn}
        />
      )}
      {children}
    </TourContext.Provider>
  );
}

export function useTour(): ContextProps {
  const context = useContext(TourContext);
  return context;
}
