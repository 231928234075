import { Box, Button, styled } from '@mui/material';

export const ContainedButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.dark,
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  gap: '20px',
}));
