/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { Tabs } from '@mui/material';
import { driver, DriveStep } from 'driver.js';
import { useContext, useEffect, useState } from 'react';

import { StepTour } from '../../api/enumerations';
import { TitleBox } from '../../components/UI/Box';
import { TitleTypography } from '../../components/UI/Typography';
import { GlobalContext } from '../../context/global';
import { driverConfig } from '../../helpers/driver/config';
import { useTour } from '../../hooks/useTour';
import Customize from './Customize';
import Plans from './Plans';
import RegisterInfo from './RegisterInfo';
import { ConfigurationWrapper, PageTab, StyledTab } from './styles';

export default function Configuration(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState('info');
  const [isResponsible, setIsResponsible] = useState(false);
  const [stepTour, setStepTour] = useState<DriveStep[]>();
  const { company } = useContext(GlobalContext);

  useEffect(() => {
    if (company) {
      setIsResponsible(company.is_responsible_user);
      setSelectedTab('info');
    }
  }, [company]);

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    setTourOn,
    toBeContinued,
    tourSelection,
    setTourSelection,
    setToBeContinued,
  } = useTour();

  const getTourSteps = (isResponsibleUser: boolean): DriveStep[] => {
    switch (isResponsibleUser) {
      case true:
        return [
          {
            element: '.sub-menu',
            popover: {
              description: 'Aqui você poderá navegar pelos sub-menus.',
              side: 'bottom',
              align: 'center',
            },
          },
          {
            element: '#info',
            popover: {
              description:
                'Aqui você poderá visualizar ou alterar informações sobre sua conta e empresa, além de possuir acesso à política de privacidade e termos de uso.',
              side: 'bottom',
              align: 'center',
            },
          },
          {
            element: '#cancel-btn',
            popover: {
              description:
                'Para descartar as alterações feitas, basta clicar em cancelar.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#save-btn',
            popover: {
              description:
                'Após efetuar alguma alteração, basta clicar aqui para salvá-las.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#plans',
            popover: {
              description:
                'Aqui você terá detalhes do seu plano escolhido e dos pagamentos.',
              side: 'bottom',
              align: 'center',
            },
          },
          {
            element: '#coverage',
            popover: {
              description:
                'Nesta área você poderá selecionar a sua área de cobertura de atendimento.',
              side: 'bottom',
              align: 'center',
            },
          },
          {
            element: '#customize',
            popover: {
              description:
                'Nesse menu você poderá configurar alguns elementos do seu relatório, como Logo e cor primária.',
              side: 'bottom',
              align: 'center',
            },
          },
          {
            element: '#save-btn',
            popover: {
              description:
                'Após essas configurações basta clicar aqui que seu próximo relatório terá o *Logo* atual e a *cor* escolhida.',
              side: 'bottom',
              align: 'center',
            },
          },
        ];
      case false:
        return [
          {
            element: '#info',
            popover: {
              description:
                'Aqui você poderá visualizar ou alterar informações sobre sua conta e empresa, além de possuir acesso à política de privacidade e termos de uso.',
              side: 'bottom',
              align: 'center',
            },
          },
          {
            element: '#cancel-btn',
            popover: {
              description:
                'Para descartar as alterações feitas, basta clicar em cancelar.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#save-btn',
            popover: {
              description:
                'Após efetuar alguma alteração, basta clicar aqui para salvá-las.',
              side: 'top',
              align: 'center',
            },
          },
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    const steps = getTourSteps(isResponsible);

    if (steps.length) {
      setStepTour(steps);
    }
  }, [isResponsible]);

  const driverObj = driver({
    ...driverConfig,
    steps: stepTour,
    onPrevClick: () => {
      const activeIndex = driverObj.getActiveIndex();
      if (activeIndex === 5) {
        setSelectedTab('plans');
      }
      if (activeIndex === 4) {
        setSelectedTab('info');
        setTimeout(() => {
          driverObj.moveTo(3);
        }, 200);
      }
      if (!isResponsible && activeIndex === 3) {
        driverObj.destroy();
        setTutorialStep((step) => step + 1);
        setTourCompletion({
          ...tourCompletion,
          configuration: { complete: true },
        });
        setTourOn(true);
      }
      driverObj.movePrevious();
    },
    onNextClick: () => {
      const activeIndex = driverObj.getActiveIndex();
      if (activeIndex === 5) {
        setSelectedTab('customize');
      }
      if (activeIndex === 3) {
        setSelectedTab('plans');
      }
      if (!isResponsible && activeIndex === 3) {
        driverObj.destroy();
        setTutorialStep(StepTour.CONFIGURATIONFINISH);
        setTourCompletion({
          ...tourCompletion,
          configuration: { complete: true },
        });
        setTourOn(true);
      }
      if (driverObj.isLastStep()) {
        setTutorialStep(StepTour.CONFIGURATIONFINISH);
        setTourCompletion({
          ...tourCompletion,
          configuration: { complete: true },
          lastStepSeen: StepTour.CONFIGURATIONFINISH,
        });
        driverObj.destroy();
        setTutorialStep(StepTour.CONFIGURATIONFINISH);
      }
      driverObj.moveNext();
    },
    onCloseClick: () => {
      driverObj.destroy();
      setTourCompletion({
        ...tourCompletion,
        lastStepSeen: StepTour.CONFIGURATIONSTART - 1,
      });
      setTourSelection(false);
      setToBeContinued(false);
    },
    onDestroyStarted: () => {
      if (driverObj?.hasNextStep()) {
        setTourSelection(false);
        setToBeContinued(false);
        setTourOn(false);
      } else if (driverObj?.isLastStep()) {
        return;
      } else {
        setTourSelection(false);
        setToBeContinued(false);
      }
      driverObj?.destroy();
    },
  });

  useEffect(() => {
    if (
      !tourCompletion.configuration.complete &&
      !tourCompletion.skipTour.configuration
    ) {
      setTutorialStep(StepTour.CONFIGURATIONSTART);
      setTourOn(true);
      if (toBeContinued) {
        driverObj.drive();
      }
    }
    if (tourSelection && toBeContinued) {
      driverObj.drive();
    }
  }, [tourSelection, toBeContinued]);

  return (
    <>
      <TitleBox>
        <TitleTypography>Configurações</TitleTypography>
      </TitleBox>
      <ConfigurationWrapper>
        <Tabs
          className="sub-menu"
          value={selectedTab}
          onChange={(e, v) => setSelectedTab(v)}
          aria-label="configuration tabs"
        >
          <StyledTab id="info" value="info" label="Dados e informações" />
          {isResponsible && (
            <StyledTab id="plans" value="plans" label="Assinatura e cobrança" />
          )}
          {isResponsible && (
            <StyledTab
              id="coverage"
              value="coverage"
              label="Local de cobertura"
              disabled
            />
          )}
          {isResponsible && (
            <StyledTab id="customize" value="customize" label="Personalizar" />
          )}
        </Tabs>
        <PageTab>
          {selectedTab === 'info' && <RegisterInfo />}
          {selectedTab === 'plans' && <Plans />}
          {selectedTab === 'customize' && <Customize />}
        </PageTab>
      </ConfigurationWrapper>
    </>
  );
}
