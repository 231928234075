/* eslint-disable */
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ExpandMore } from '@mui/icons-material';
import { StepTour } from '../../api/enumerations';
import { PutGuideTour } from '../../api/users';
import { TourData } from '../../api/users/types';
import { GlobalContext } from '../../context/global';
import { getErrorMessage } from '../../helpers';
import tourInitialState from '../../helpers/driver/tourInitialState';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import Snackbar from '../Snackbar';
import {
  DrawerTitle,
  PercentageLineAndValue,
  Separator,
  StyledFormControlLabel,
  StyledGridContainer,
  StyledModalContainer,
  TextButton,
} from './styles';

export function ProgressBox(): JSX.Element {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [expandedTwo, setExpandedTwo] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    setTourCompletion,
    setTutorialStep,
    setTourOn,
    isProgressOpen,
    setProgressOpen,
    tourCompletion,
    peptWorkOrders,
    schedulingWorkOrders,
    inspectionWorkOrders,
    sampleCreationWorkOrders,
    calculationWorkOrders,
    reportWorkOrders,
    revisionWorkOrders,
    setTourSelection,
    tourPercentage,
    setDriveIsActive,
    drawerOpen,
  } = useTour();

  const { navigateHome } = useGeneral();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const decoded = useStoragedJwt();

  const tutorials = [
    {
      name: 'onboarding',
      step: StepTour.GUIDEDTOUR,
      route: '/home',
      check: 'onBoarding',
    },
    {
      name: 'pipeline',
      step: StepTour.PIPELINESTART,
      route: '/home',
      check: 'pipeline',
    },
    {
      name: 'entrada de dados',
      step: StepTour.ENTRANCESTART,
      route: '/home/property/new',
      check: 'entrance',
    },
    {
      name: 'pendência técnica',
      step: StepTour.PEPTSTART,
      route: peptWorkOrders.length
        ? `/home/property/${peptWorkOrders[0].id}/pept`
        : '#',
      check: 'pept',
      disabled: !(peptWorkOrders.length > 0),
    },
    {
      name: 'agendamento',
      step: StepTour.SCHEDULESTART,
      route: schedulingWorkOrders.length
        ? `/home/property/${schedulingWorkOrders[0].id}/schedule`
        : '#',
      check: 'schedule',
      disabled: !(schedulingWorkOrders.length > 0),
    },
    {
      name: 'vistoria',
      step: StepTour.INSPECTIONSTART,
      route: inspectionWorkOrders.length
        ? `/home/property/${inspectionWorkOrders[0].id}/inspection`
        : '#',
      check: 'inspection',
      disabled: !(inspectionWorkOrders.length > 0),
    },
    {
      name: 'criação de amostra',
      step: StepTour.SAMPLECREATESTART,
      route: sampleCreationWorkOrders.length
        ? `/home/property/${sampleCreationWorkOrders[0].id}/sample`
        : '#',
      check: 'sampleCreate',
      disabled: !(sampleCreationWorkOrders.length > 0),
    },
    {
      name: 'processando cálculo',
      step: StepTour.CALCULATIONSTART,
      route: calculationWorkOrders.length
        ? `/home/property/${calculationWorkOrders[0].id}/calculation`
        : '#',
      check: 'calculation',
      disabled: !(calculationWorkOrders.length > 0),
    },
    {
      name: 'elaboração de laudo',
      step: StepTour.REPORTSTART,
      route: reportWorkOrders.length
        ? `/home/property/${reportWorkOrders[0].id}/report`
        : '#',
      check: 'report',
      disabled: !(reportWorkOrders.length > 0),
    },
    {
      name: 'revisão e aprovação',
      step: StepTour.REVISIONSTART,
      route: revisionWorkOrders.length
        ? `/home/property/${revisionWorkOrders[0].id}/revision`
        : '#',
      check: 'revision',
      disabled: !(revisionWorkOrders.length > 0),
    },
    {
      name: 'arquivados',
      step: StepTour.ARCHIVEDSTART,
      route: '/archived',
      check: 'archived',
    },
    {
      name: 'usuários',
      step: StepTour.USERSSTART,
      route: '/users',
      check: 'users',
    },
    {
      name: 'formulários',
      step: StepTour.FORMSTART,
      route: '/forms',
      check: 'form',
    },
    {
      name: 'configurações',
      step: StepTour.CONFIGURATIONSTART,
      route: '/configuration',
      check: 'configuration',
    },
    {
      name: 'agenda ',
      step: StepTour.AGENDASTART,
      route: '/agenda',
      check: 'agenda',
    },
    {
      name: 'erros reportados',
      step: StepTour.ERRORREPORTSTART,
      route: '/error-report',
      check: 'errorReport',
    },
  ];

  const inspection = [
    {
      name: 'vistoria',
      step: 26,
      route: inspectionWorkOrders.length
        ? `/home/property/${inspectionWorkOrders[0].id}/inspection`
        : '#',
      check: 'inspection',
      disabled: true,
    },
    {
      name: 'cômodos',
      step: 26,
      route: inspectionWorkOrders.length
        ? `/home/property/${inspectionWorkOrders[0].id}/inspection`
        : '#',
      check: 'inspectionRooms',
      disabled: true,
    },
    {
      name: 'adicionar fotos',
      step: 26,
      route: '#',
      check: 'inspectionRoomsAddPhotos',
      disabled: true,
    },
    {
      name: 'detalhes da imagem',
      step: 26,
      route: '#',
      check: 'inspectionRoomsImageDetails',
      disabled: true,
    },
    {
      name: 'patologias',
      step: 26,
      route: '#',
      check: 'inspectionPathologies',
      disabled: true,
    },
    {
      name: 'galeria de fotos',
      step: 26,
      route: '#',
      check: 'inspectionGallery',
      disabled: true,
    },
  ];

  const sample = [
    {
      name: 'criação de amostra',
      step: StepTour.SAMPLECREATESTART,
      route: '#',
      check: 'sampleCreate',
      disabled: true,
    },
    {
      name: 'adicionar elemento',
      step: StepTour.SAMPLECREATESTART,
      route: '#',
      check: 'addElement',
      disabled: true,
    },
  ];

  const handleTourSelection = useCallback(
    (step: number, route: string): void => {
      setTutorialStep(step);
      setDriveIsActive(true);
      setTourOn(true);
      setProgressOpen(false);
      setTourSelection(true);
      navigate(route);
    },
    [navigate, setProgressOpen, setTourOn, setTutorialStep, setDriveIsActive]
  );

  const handleResetTour = async () => {
    setTourCompletion(tourInitialState);
    navigateHome();

    const guide_tour = JSON.stringify(tourInitialState);

    if (decoded) {
      try {
        const response = await PutGuideTour(decoded?.user.id, guide_tour);
        if (response.detail?.description) {
          throw new Error(response.detail.description);
        }

        setProgressOpen(false);
        setTutorialStep(0);
        setTourOn(true);

        setSnackbarMessage('Tour resetado para o estado inicial');
        setErrorMessage(false);
        setOpenSnackbar(true);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  };

  const handleResumeTour = (): void => {
    setLoading(true);
    tutorials.forEach((el, index) => {
      if (tourCompletion.lastStepSeen + 1 === el.step) {
        if (el.disabled) {
          setSnackbarMessage(
            `Não foi possível exibir o próximo passo do tour, pois não há nenhuma OS na coluna de ${el.name}`
          );
          setErrorMessage(true);
          setOpenSnackbar(true);
          setLoading(false);
          return;
        }
        setLoading(false);
        setProgressOpen(false);
        navigate(tutorials[index].route);
        setTourOn(true);
      }
    });
  };

  const handleClose = (): void => setProgressOpen(false);

  return (
    <>
      <StyledModalContainer open={isProgressOpen} onClose={handleClose}>
        <StyledGridContainer
          container
          id="modal-progress"
          className={drawerOpen ? 'drawerOpen' : ''}
        >
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%', padding: '0 16px' }}
          >
            <Grid item>
              <ConfirmationDialog
                text="resetar o Tour Guiado"
                button={<TextButton>resetar tour</TextButton>}
                modalCallback={handleResetTour}
              />
            </Grid>
            <Grid item>
              <TextButton
                disabled={tourPercentage === 100}
                onClick={handleResumeTour}
              >
                {loading ? <CircularProgress size={24} /> : 'retomar tour'}
              </TextButton>
            </Grid>
          </Grid>
          <Separator />
          <Grid item sx={{ width: '100%', padding: '0 8px' }}>
            <PercentageLineAndValue>
              <LinearProgress
                color="secondary"
                variant="determinate"
                value={tourPercentage}
                sx={{ height: '10px', borderRadius: '8px', flex: 1 }}
              />
              {tourPercentage}%
            </PercentageLineAndValue>
          </Grid>
          <Grid item>
            <DrawerTitle>
              Selecione a tela que gostaria de realizar o tour:
            </DrawerTitle>

            {tutorials.map(({ name, step, route, check, disabled }) => {
              return (
                <Grid key={name} container>
                  {name === 'vistoria' ? (
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Tooltip
                          title={
                            disabled ? 'Não há nenhuma OS nesta coluna' : ''
                          }
                        >
                          <StyledFormControlLabel
                            disabled={disabled}
                            label={
                              <Typography
                                className={disabled ? 'disabled' : ''}
                                onClick={() =>
                                  disabled
                                    ? ''
                                    : handleTourSelection(step, route)
                                }
                              >
                                {name}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checked={
                                  check === 'inspection'
                                    ? !tourCompletion[check].some(
                                        (element) => element.complete === false
                                      )
                                    : !!tourCompletion[check as keyof TourData]
                                }
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<CircleIcon />}
                                onClick={() =>
                                  disabled
                                    ? ''
                                    : handleTourSelection(step, route)
                                }
                              />
                            }
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => setExpanded(!expanded)}>
                          <ExpandMore color="secondary" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : name === 'criação de amostra' ? (
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Tooltip
                          title={
                            disabled ? 'Não há nenhuma OS nesta coluna' : ''
                          }
                        >
                          <StyledFormControlLabel
                            disabled={disabled}
                            label={
                              <Typography
                                className={disabled ? 'disabled' : ''}
                                onClick={() =>
                                  disabled
                                    ? ''
                                    : handleTourSelection(step, route)
                                }
                              >
                                {name}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checked={
                                  check === 'sampleCreate'
                                    ? !tourCompletion[check].some(
                                        (element) => element.complete === false
                                      )
                                    : !!tourCompletion[check as keyof TourData]
                                }
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<CircleIcon />}
                                onClick={() =>
                                  disabled
                                    ? ''
                                    : handleTourSelection(step, route)
                                }
                              />
                            }
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => setExpandedTwo(!expandedTwo)}
                        >
                          <ExpandMore color="secondary" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Tooltip
                        title={disabled ? 'Não há nenhuma OS nesta coluna' : ''}
                      >
                        <StyledFormControlLabel
                          disabled={disabled}
                          label={
                            <Typography
                              className={disabled ? 'disabled' : ''}
                              onClick={() =>
                                disabled ? '' : handleTourSelection(step, route)
                              }
                            >
                              {name}
                            </Typography>
                          }
                          control={
                            <Checkbox
                              checked={
                                check === 'inspection'
                                  ? !tourCompletion[check].every(
                                      (element) => element.complete === false
                                    )
                                  : !!(
                                      (check as keyof TourData) in
                                        tourCompletion &&
                                      (
                                        tourCompletion[
                                          check as keyof TourData
                                        ] as { complete: boolean }
                                      )?.complete
                                    )
                              }
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CircleIcon />}
                              onClick={() =>
                                disabled ? '' : handleTourSelection(step, route)
                              }
                            />
                          }
                        />
                      </Tooltip>
                    </Grid>
                  )}
                  {expanded && (
                    <Grid container direction="column">
                      {name === 'vistoria' &&
                        inspection.map((item, index) => {
                          return (
                            <Grid
                              item
                              key={item.name}
                              sx={{ padding: '0 20px' }}
                            >
                              <StyledFormControlLabel
                                style={{
                                  cursor: item.disabled ? 'not-allowed' : '',
                                }}
                                disabled={item.disabled}
                                label={<Typography>{item.name}</Typography>}
                                control={
                                  <Checkbox
                                    checked={
                                      !!tourCompletion.inspection[index]
                                        .complete
                                    }
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CircleIcon />}
                                  />
                                }
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                  {expandedTwo && (
                    <Grid container direction="column">
                      {name === 'criação de amostra' &&
                        sample.map((item, index) => {
                          return (
                            <Grid
                              item
                              key={item.name}
                              sx={{ padding: '0 20px' }}
                            >
                              <StyledFormControlLabel
                                style={{
                                  cursor: item.disabled ? 'not-allowed' : '',
                                }}
                                disabled={item.disabled}
                                label={<Typography>{item.name}</Typography>}
                                control={
                                  <Checkbox
                                    checked={
                                      tourCompletion.sampleCreate[index]
                                        .complete
                                    }
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CircleIcon />}
                                  />
                                }
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </StyledGridContainer>
      </StyledModalContainer>
      {openSnackbar && <Snackbar />}
    </>
  );
}
