import { Box, Typography } from '@mui/material';
import { ChangeEvent } from 'react';

import { IconDownloadMS, IconUploadMS } from '../../../constants/icons';
import useGeneral from '../../../hooks/useGeneral';
import { StandardButton } from '../../UI/Button';
import { Constants } from './constants';
import { ButtonContainer, ButtonBox, ButtonTitle } from './styles';

interface WorkOrderFileProps {
  downloadRegister: string;
  downloadIptu: string;
  registerFileName: string;
  iptuFileName: string;
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => Promise<void> | void;
}

export function WorkOrderFile({
  downloadRegister,
  downloadIptu,
  registerFileName,
  iptuFileName,
  handleFileUpload,
}: WorkOrderFileProps): JSX.Element {
  const { handleDownloadFile } = useGeneral();
  return (
    <>
      <Typography>{Constants.downloadText}</Typography>
      <ButtonContainer id="buttons-container">
        <ButtonBox>
          <ButtonTitle>{Constants.propertyRegister}</ButtonTitle>
          {downloadRegister.length > 0 ? (
            <StandardButton
              component="span"
              onClick={() => {
                if (downloadRegister)
                  handleDownloadFile(downloadRegister, 'matricula');
              }}
            >
              {IconDownloadMS}
              {Constants.download}
            </StandardButton>
          ) : (
            <>
              <label htmlFor="uploadRegister">
                <input
                  accept="image/*,application/pdf"
                  id="uploadRegister"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                    handleFileUpload(e, 'register')
                  }
                />
                <StandardButton component="span">
                  {IconUploadMS}
                  {Constants.upload}
                </StandardButton>
              </label>
              {registerFileName && <Box mt={1}>{registerFileName}</Box>}
            </>
          )}
        </ButtonBox>
        <ButtonBox>
          <ButtonTitle>{Constants.iptu}</ButtonTitle>
          {downloadIptu.length > 0 ? (
            <StandardButton
              component="span"
              onClick={() => {
                if (downloadIptu) handleDownloadFile(downloadIptu, 'iptu');
              }}
            >
              {IconDownloadMS}
              {Constants.download}
            </StandardButton>
          ) : (
            <>
              <label htmlFor="uploadIptu">
                <input
                  accept="image/*,application/pdf"
                  id="uploadIptu"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                    handleFileUpload(e, 'iptu')
                  }
                />
                <StandardButton component="span">
                  {IconUploadMS}
                  {Constants.upload}
                </StandardButton>
              </label>
              {iptuFileName && <Box mt={1}>{iptuFileName}</Box>}
            </>
          )}
        </ButtonBox>
      </ButtonContainer>
    </>
  );
}
