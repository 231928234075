/* eslint-disable react/require-default-props */
import { Box, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { potencialCustomerAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { CloseIcon, FilledButton } from '../../../components/UI/Button';
import { Constants } from '../../../constants/createAccount';
import { IconCloseMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, validateEmail } from '../../../helpers';
import useGeneral from '../../../hooks/useGeneral';
import { ButtonContainer, Dialog, StyledText, Title } from './styles';

interface PotencialCustomerData {
  city: string;
  uf: number;
}

export default function RegisterEmail({
  city,
  uf,
}: PotencialCustomerData): JSX.Element {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const dialog = useGeneral();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (emailError) {
      setSnackbarMessage('Email inválido');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const data = {
      city,
      uf,
      email,
    };

    try {
      const response = await potencialCustomerAPI.registerPotencialCustomer(
        data
      );
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setSnackbarMessage('Email cadastrado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      dialog.handleClose();
      navigate('/login');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <>
      <FilledButton onClick={dialog.handleOpen}>
        {Constants.registerEmail}
      </FilledButton>
      <Dialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="report error dialog"
      >
        <CloseIcon onClick={dialog.handleClose} disableTouchRipple>
          {IconCloseMS}
        </CloseIcon>
        <Title>{Constants.interestRegister}</Title>
        <StyledText>{Constants.registerInfo}</StyledText>
        <Box
          width="100%"
          component="form"
          id="register-email"
          onSubmit={handleSubmit}
        >
          <TextField
            required
            id="email"
            color="secondary"
            label={Constants.email}
            error={emailError}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              !validateEmail(e.target.value)
                ? setEmailError(true)
                : setEmailError(false);
            }}
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: '16px',
                width: 550,
              },
            }}
          />
          <ButtonContainer>
            <FilledButton form="register-email" type="submit">
              {Constants.send}
            </FilledButton>
          </ButtonContainer>
        </Box>
      </Dialog>
    </>
  );
}
