/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
import { Box, CircularProgress, Grid, Slider, Typography } from '@mui/material';
import { driver, DriveStep } from 'driver.js';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import {
  convertClientKind,
  convertEvaluationType,
  convertPropertyType,
  EvaluationType,
  PropertyType,
  StepTour,
} from '../../api/enumerations';
import {
  CustomTextField,
  NumericTextField,
  SelectTextField,
} from '../../components/CustomInput';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { PeptDialog } from '../../components/Dialog/PeptDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { EditWorkOrder } from '../../components/Sections/EditWorkOrder';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { Rooms } from '../../components/Sections/Rooms';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { WorkOrderFile } from '../../components/Sections/WorkOrderFile';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  ButtonBox,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
  SectionBox,
  SubmitBox,
} from '../../components/UI/Box';
import {
  BackButton,
  CancelOSButton,
  FilledButton,
  OutlinedButton,
  RoundedButton,
} from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import {
  SectionTitle,
  StyledInput,
  WhiteCircularProgress,
} from '../../components/UI/Typography';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconCancelMS,
  IconCloseMS,
  IconDeleteMS,
  IconEditMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPersonMS,
  IconPhotoLibraryMS,
  IconSearchMS,
} from '../../constants/icons';
import { Constants } from '../../constants/sampleCreation';
import {
  selectConcept,
  selectConservation,
  selectPropertyUse,
  selectRegistrationUf,
  selectReportFunction,
  selectReportGoal,
  selectStandard,
  selectStandard400K,
} from '../../constants/selectOptions';
import { convertFloatDot } from '../../helpers';
import { driverConfig } from '../../helpers/driver/config';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import { AddElement } from './AddElement';
import useSampleCreation from './hooks';
import { Sample } from './Sample';
import {
  CloseIcon,
  DeleteButton,
  DialogBox,
  DialogTypography,
  ExtrapolationsErrors,
  FlexBox,
  InputTitle,
  SliderGrid,
  SmallStyledInput,
  StyledDialog,
  StyledGrid,
  StyledTextField,
} from './styles';

// eslint-disable-next-line complexity
export default function SampleCreation(): JSX.Element {
  const [stepTour, setStepTour] = useState<DriveStep[]>();
  const [tourHasStarted, setTourHasStarted] = useState(false);

  const { navigateHome, osId } = useGeneral();
  const { handleStatus, loadingApprove, setLoadingApprove } = useChangeStatus();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();
  const {
    handleApproveConservation,
    checkApprovedSample,
    handleSearchSample,
    propertyData,
    disableInfo,
    age,
    setAge,
    conservation,
    setConservation,
    buildingStandard,
    setBuildingStandard,
    loading,
    sampleData,
    completeElements,
    incompleteElements,
    pinPlace,
    showMap,
    showModal,
    evaluationTypeIsFactors,
    getSample,
    disapprovedElements,
    setDisapprovedElements,
    minElements,
    rooms,
    handleDeleteSample,
    setSupplyFactor,
    isSupplyEditionDisabled,
    supplyFactor,
    setIsSupplyEditionDisabled,
    extrapolationsErrors,
    isConfirmChangesLoading,
    showAddressModal,
    setShowAddressModal,
    handleNewSupplyFactor,
    loadingDelete,
    loadingPage,
    enableEditing,
    setEnableEditing,
    client,
    setClient,
    solicitor,
    setSolicitor,
    goal,
    setGoal,
    propertyFunction,
    setPropertyFunction,
    propertyUse,
    setPropertyUse,
    handleFileUpload,
    registerFileName,
    iptuFileName,
    downloadIptu,
    downloadRegister,
    registerNumber,
    setRegisterNumber,
    concept,
    setConcept,
    zone,
    setZone,
    judicialDistrict,
    setJudicialDistrict,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    toilets,
    setToilets,
    suites,
    setSuites,
    handleSubmit,
    submitLoading,
    page,
    setPage,
    lastElementEdited,
    setLastElementEdited,
    maxRadius,
    setMaxRadius,
    parkingError,
  } = useSampleCreation();

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    setToBeContinued,
    setTourOn,
    isTourOn,
    tourSelection,
    setTourSelection,
    driveIsActive,
    setDriveIsActive,
  } = useTour();

  const factorsMax = 12;
  const inferenceMax = 70;

  const getTourSteps = (sampleData: boolean): DriveStep[] => {
    switch (sampleData) {
      case true:
        return [
          {
            element: '.conservation-state',
            popover: {
              description:
                'Nesses campos você deverá confirmar a idade estimada (ou estimar, caso não haja essa informação), depois calcular o estado de conservação e confirmar o padrão construtivo do elemento.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#supply-offer',
            popover: {
              description:
                'Neste campo você poderá escolher o percentual do fator oferta (valor descontado do preço anunciado do imóvel).',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#delete-sample',
            popover: {
              description:
                'Caso você queira buscar uma nova amostra, você poderá excluir a amostra e fazer uma nova busca.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#sample-length',
            popover: {
              description:
                'Neste campo, mostramos a quantidade de elementos que a busca trouxe.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#map-location',
            popover: {
              description:
                'Aqui é exibido um mapa com o imóvel avaliando e os elementos encontrados ao seu redor. Os elementos podem ser filtrados pelo Pin no mapa para mostrar suas informações.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#dashboard',
            popover: {
              description:
                'Este espaço abaixo funciona como um dashboard para gerenciar os elementos, aqui é possível filtrar os elementos incompletos ou navegar pelos elementos de uma forma mais fácil. Você também poderá mudar o tipo de visualização para o que achar mais confortável para sua análise.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#element-info',
            popover: {
              description:
                'Essa parte da tela trás as informações de um elemento, aqui você pode editar os dados caso necessário e conseguirá efetuar a análise com as informações trazidas. Além disso, caso necessário você poderá excluir um determinado item da amostra.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#error-report',
            popover: {
              description:
                'Caso haja algum erro no elemento que não seja passível de correção, você pode clicar neste botão para reportar o erro. Ele sairá de sua amostra e o elemento irá para uma análise mais profunda para correção.',
              side: 'left',
              align: 'center',
            },
          },
          {
            element: '#delete-element',
            popover: {
              description:
                'Caso haja algum problema com o elemento, você pode excluí-lo clicando neste botão.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#element-images',
            popover: {
              description:
                'Aqui você poderá visualizar as imagens que foram inseridas no anúncio, elas poderão te ajudar na estimativa de idade, padrão construtivo, estado de conservação e eventualmente confirmar o endereço do imóvel utilizando a foto de fachada, quando disponível.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#navigation-button',
            popover: {
              description:
                'Você poderá utilizar esse botões para navegar entre os elementos quando a visualização estiver paginada (mostrando apenas 1 elemento de cada vez).',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#add-element',
            popover: {
              description:
                'Aqui você poderá inserir elementos manualmente, caso não haja elementos encontrados automaticamente ou se os elementos encontrados não forem suficientes para concluir o cálculo.',
              side: 'top',
              align: 'center',
            },
          },
        ];
      case false:
        return [
          {
            element: '.conservation-state',
            popover: {
              description:
                'Nesses campos você deverá confirmar a idade estimada (ou estimar, caso não haja essa informação), depois calcular o estado de conservação e confirmar o padrão construtivo do elemento.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#search-sample',
            popover: {
              description:
                'Para buscar os elementos para compor a amostra, basta clicar nesse botão que o sistema irá efetuar a busca.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#supply-offer',
            popover: {
              description:
                'Neste campo você poderá escolher o percentual do fator oferta (valor descontado do preço anunciado do imóvel).',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#delete-sample',
            popover: {
              description:
                'Caso você queira buscar uma nova amostra, você poderá excluir a amostra e fazer uma nova busca.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#sample-length',
            popover: {
              description:
                'Neste campo, mostramos a quantidade de elementos que a busca trouxe.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#map-location',
            popover: {
              description:
                'Aqui é exibido um mapa com o imóvel avaliando e os elementos encontrados ao seu redor. Os elementos podem ser filtrados pelo Pin no mapa para mostrar suas informações.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#dashboard',
            popover: {
              description:
                'Este espaço abaixo funciona como um dashboard para gerenciar os elementos, aqui é possível filtrar os elementos incompletos ou navegar pelos elementos de uma forma mais fácil. Você também poderá mudar o tipo de visualização para o que achar mais confortável para sua análise.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#element-info',
            popover: {
              description:
                'Essa parte da tela trás as informações de um elemento, aqui você pode editar os dados caso necessário e conseguirá efetuar a análise com as informações trazidas. Além disso, caso necessário você poderá excluir um determinado item da amostra.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#error-report',
            popover: {
              description:
                'Caso haja algum erro no elemento que não seja passível de correção, você pode clicar neste botão para reportar o erro. Ele sairá de sua amostra e o elemento irá para uma análise mais profunda para correção.',
              side: 'left',
              align: 'center',
            },
          },
          {
            element: '#delete-element',
            popover: {
              description:
                'Caso haja algum problema com o elemento, você pode excluí-lo clicando neste botão.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#element-images',
            popover: {
              description:
                'Aqui você poderá visualizar as imagens que foram inseridas no anúncio, elas poderão te ajudar na estimativa de idade, padrão construtivo, estado de conservação e eventualmente confirmar o endereço do imóvel utilizando a foto de fachada, quando disponível.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#navigation-button',
            popover: {
              description:
                'Você poderá utilizar esse botões para navegar entre os elementos quando a visualização estiver paginada (mostrando apenas 1 elemento de cada vez).',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#add-element',
            popover: {
              description:
                'Aqui você poderá inserir elementos manualmente, caso não haja elementos encontrados automaticamente ou se os elementos encontrados não forem suficientes para concluir o cálculo.',
              side: 'top',
              align: 'center',
            },
          },
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (lastElementEdited) {
      setPage(lastElementEdited);
      setLastElementEdited(undefined);
    }
  }, [sampleData]);

  useEffect(() => {
    let steps: DriveStep[] = [];
    if (sampleData === undefined) {
      steps = getTourSteps(false);
    } else if (sampleData.length) {
      steps = getTourSteps(true);
    }
    setStepTour(steps);
  }, [sampleData]);

  const driverObj = driver({
    ...driverConfig,
    steps: stepTour,
    onPrevClick: () => {
      const activeIndex = driverObj.getActiveIndex();
      if (activeIndex === 12) {
        const closeIcon = document.getElementById('close-icon');
        closeIcon?.click();
      }
      if (activeIndex === 1 && sampleData?.length) {
        driverObj.movePrevious();
      }
      driverObj.movePrevious();
    },
    onNextClick: () => {
      const activeIndex = driverObj.getActiveIndex() || 0;
      if (activeIndex === 2 && !sampleData?.length) {
        driverObj.destroy();
        setTourCompletion((prev) => {
          const newTourCompletion = { ...prev };

          newTourCompletion.sampleCreate[0].step = activeIndex;
          return newTourCompletion;
        });
      }
      if (activeIndex === 0 && sampleData?.length) {
        driverObj.moveNext();
      }
      if (driverObj.isLastStep()) {
        setTourCompletion((prev) => {
          const newTourCompletion = { ...prev };
          newTourCompletion.sampleCreate[0].step = activeIndex;
          newTourCompletion.sampleCreate[0].complete = true;
          return newTourCompletion;
        });
        setToBeContinued(false);
        setTourSelection(false);
        setDriveIsActive(false);
      }
      driverObj.moveNext();
    },
    onCloseClick: () => {
      setTourSelection(false);
      setToBeContinued(false);
      setDriveIsActive(false);
      driverObj.destroy();
    },
    onDestroyStarted: () => {
      setTourSelection(false);
      setToBeContinued(false);
      setDriveIsActive(false);
      driverObj.destroy();
    },
  });

  useEffect(() => {
    if (
      !tourCompletion.sampleCreate[0].complete &&
      !tourCompletion.skipTour.sampleCreate
    ) {
      setTutorialStep(StepTour.SAMPLECREATESTART);
      if (
        !driveIsActive &&
        !tourHasStarted &&
        !loadingPage &&
        tourCompletion.sampleCreate[0].step === 0
      ) {
        setTourOn(true);
        setTourHasStarted(true);
      } else if (
        !isTourOn &&
        driveIsActive &&
        !loadingPage &&
        !showAddressModal
      ) {
        if (tourCompletion.sampleCreate[0].step > 0) {
          if (sampleData?.length) {
            driverObj.moveTo(tourCompletion.sampleCreate[0].step + 1);
          }
        } else {
          driverObj.drive();
        }
      }
    }
    if (tourSelection && driveIsActive && !isTourOn) {
      setTutorialStep(StepTour.SAMPLECREATESTART);
      setTimeout(() => {
        driverObj.drive();
      }, 250);
    }

    if (showAddressModal) {
      driverObj.destroy();
    }
  }, [tourCompletion, driveIsActive, isTourOn, loadingPage, showAddressModal]);

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
  ]);

  useEffect(() => {
    setExpandThree(true);
    setExpandSeven(true);
  }, []);

  const showSchedulingAccordion =
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPINFERENCES;

  const showCompleteSampleButton = (): boolean => {
    if (!sampleData) return false;

    if (!evaluationTypeIsFactors && sampleData.length < inferenceMax)
      return true;
    if (evaluationTypeIsFactors && sampleData.length < factorsMax) return true;
    return false;
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer component="form" id="sample" onSubmit={handleSubmit}>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.sampleCreation}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <FlexBox>
                <CancelOsBox>
                  <ConfirmationDialog
                    text={Constants.cancelOsText}
                    button={
                      <CancelOSButton>
                        {IconCancelMS}
                        {Constants.cancelOs}
                      </CancelOSButton>
                    }
                    model="error"
                    modalCallback={handleCancelWorkOrder}
                  />
                </CancelOsBox>
                <EditWorkOrder
                  edit={enableEditing}
                  setEdit={setEnableEditing}
                />
              </FlexBox>
              <AttachFilesDialog propertyData={propertyData} osId={osId} />
            </FlexSpaceBetweenBox>
            {enableEditing ? (
              <SectionBox>
                <SectionTitle mb="20px">
                  {IconPersonMS}
                  {Constants.clientData}
                </SectionTitle>
                <Grid container spacing={4}>
                  <Grid item xs={6} xl={4}>
                    <CustomTextField
                      required
                      id="client"
                      label="proprietário do imóvel"
                      value={client}
                      setValue={setClient}
                    />
                  </Grid>
                  <Grid item xs={6} xl={4}>
                    <CustomTextField
                      id="solicitor"
                      label="solicitante"
                      value={solicitor}
                      setValue={setSolicitor}
                    />
                  </Grid>
                  <Grid item xs={6} xl={4}>
                    <SelectTextField
                      id="os-goal"
                      label="objetivo"
                      value={goal}
                      setValue={setGoal}
                      selectOptions={selectReportGoal()}
                    />
                  </Grid>
                  <Grid item xs={6} xl={3}>
                    <InputTitle>{Constants.evaluationType}</InputTitle>
                    <StyledInput>
                      {propertyData &&
                        convertEvaluationType(propertyData.evaluation_type)}
                    </StyledInput>
                  </Grid>
                  <Grid item xs={4} xl={3}>
                    <InputTitle>{Constants.clientType}</InputTitle>
                    <StyledInput>
                      {propertyData &&
                        convertClientKind(propertyData.client_kind)}
                    </StyledInput>
                  </Grid>
                  <Grid item xs={4} xl={3}>
                    <SelectTextField
                      id="os-function"
                      label="finalidade"
                      value={propertyFunction}
                      setValue={setPropertyFunction}
                      selectOptions={selectReportFunction()}
                    />
                  </Grid>
                  <Grid item xs={4} xl={3}>
                    <SelectTextField
                      id="property-use"
                      label="uso do imóvel"
                      value={propertyUse}
                      setValue={setPropertyUse}
                      selectOptions={selectPropertyUse()}
                    />
                  </Grid>
                </Grid>
              </SectionBox>
            ) : (
              <ClientData propertyData={propertyData} />
            )}
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne &&
                  (enableEditing ? (
                    <>
                      <WorkOrderFile
                        downloadRegister={downloadRegister}
                        downloadIptu={downloadIptu}
                        registerFileName={registerFileName}
                        iptuFileName={iptuFileName}
                        handleFileUpload={handleFileUpload}
                      />
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="register-number"
                            label="nº da matrícula"
                            value={registerNumber}
                            setValue={setRegisterNumber}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputTitle>{Constants.propertyType}</InputTitle>
                          <StyledInput>
                            {propertyData &&
                              convertPropertyType(
                                propertyData.real_estate_kind
                              )}
                          </StyledInput>
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="concept"
                            label="conceito do espaço"
                            value={concept}
                            setValue={setConcept}
                            selectOptions={selectConcept()}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumericTextField
                            id="zone"
                            label="ofício/zona"
                            suffix=""
                            maxLength={12}
                            value={zone}
                            setValue={setZone}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="judicial-district"
                            label="comarca"
                            value={judicialDistrict}
                            setValue={setJudicialDistrict}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="registration-uf"
                            label="UF"
                            value={registrationUf}
                            setValue={setRegistrationUf}
                            selectOptions={selectRegistrationUf()}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <PropertyData propertyData={propertyData} />
                  ))}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && (
                  <Grid container spacing={4} className="conservation-state">
                    <Grid item xs={2}>
                      <InputTitle>
                        {propertyData?.real_estate_kind ===
                        PropertyType.APARTMENT
                          ? Constants.totalArea
                          : Constants.landArea}
                      </InputTitle>
                      <SmallStyledInput>
                        {propertyData?.total_area}
                      </SmallStyledInput>
                    </Grid>
                    <Grid item xs={2}>
                      <InputTitle>
                        {propertyData?.real_estate_kind ===
                        PropertyType.APARTMENT
                          ? Constants.privateArea
                          : Constants.constructedArea}
                      </InputTitle>
                      <SmallStyledInput>
                        {propertyData?.constructed_area}
                      </SmallStyledInput>
                    </Grid>
                    {enableEditing ? (
                      <>
                        <Grid item xs={2}>
                          <NumericTextField
                            id="rooms"
                            label="quartos"
                            suffix=" quartos"
                            maxLength={12}
                            value={propertyRooms}
                            setValue={setPropertyRooms}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <NumericTextField
                            id="toilets"
                            label="banheiros"
                            suffix=" banheiros"
                            maxLength={13}
                            value={toilets}
                            setValue={setToilets}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <NumericTextField
                            id="suites"
                            label="suítes"
                            suffix=" suítes"
                            maxLength={10}
                            value={suites}
                            setValue={setSuites}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={2}>
                          <InputTitle>{Constants.rooms}</InputTitle>
                          <SmallStyledInput>
                            {propertyData?.bedrooms}
                          </SmallStyledInput>
                        </Grid>
                        <Grid item xs={2}>
                          <InputTitle>{Constants.toilets}</InputTitle>
                          <SmallStyledInput>
                            {propertyData?.bathrooms}
                          </SmallStyledInput>
                        </Grid>
                        <Grid item xs={2}>
                          <InputTitle>{Constants.suites}</InputTitle>
                          <SmallStyledInput>
                            {propertyData?.suites}
                          </SmallStyledInput>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={2}>
                      <InputTitle>{Constants.parkingLots}</InputTitle>
                      <SmallStyledInput>
                        {propertyData?.parking_spaces}
                      </SmallStyledInput>
                    </Grid>
                    <Grid item xs={3.5}>
                      <NumericTextField
                        disable={disableInfo}
                        id="property-age-estimate"
                        label="idade do imóvel"
                        suffix=" anos"
                        maxLength={9}
                        value={age}
                        setValue={setAge}
                      />
                    </Grid>
                    <Grid item xs={8} />
                    <Grid item xs={3.5}>
                      <SelectTextField
                        disable={disableInfo}
                        id="conservation-age-estimate"
                        label="estado de conservação do imóvel"
                        value={conservation}
                        setValue={setConservation}
                        selectOptions={selectConservation()}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SelectTextField
                        disable={disableInfo}
                        id="standard-age-estimate"
                        label="padrão construtivo"
                        value={buildingStandard}
                        setValue={setBuildingStandard}
                        selectOptions={
                          propertyData?.sample_sell_price_above_400k
                            ? selectStandard400K()
                            : selectStandard()
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <RoundedButton
                        model="filled"
                        width="sm"
                        disabled={disableInfo}
                        onClick={handleApproveConservation}
                      >
                        {disableInfo ? Constants.approved : Constants.approve}
                      </RoundedButton>
                    </Grid>
                  </Grid>
                )}
              </SectionBox>
              {showSchedulingAccordion && (
                <SectionBox>
                  <AccordionTitle
                    title={Constants.scheduleInfo}
                    icon={IconCalendarTodayMS}
                    openAccordion={expandFour}
                    setOpenAccordion={setExpandFour}
                  />
                  {expandFour && (
                    <Box>
                      {propertyData?.inspection ? (
                        <ScheduleInfo
                          inspectionData={propertyData.inspection}
                          rooms={rooms}
                        />
                      ) : (
                        <Typography>{Constants.noInspection}</Typography>
                      )}
                    </Box>
                  )}
                </SectionBox>
              )}
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response ? (
                      <FormResponse
                        questionForm={propertyData.inspection.form_response}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.samples}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={1.5}>
                        <NumericFormat
                          customInput={StyledTextField}
                          label="fator oferta"
                          id="supply-offer"
                          className="supply-offer"
                          color="secondary"
                          suffix=" %"
                          decimalSeparator="."
                          decimalScale={2}
                          allowNegative={false}
                          inputProps={{ maxLength: 7 }}
                          value={supplyFactor}
                          onChange={(e) => {
                            setSupplyFactor(convertFloatDot(e.target.value));
                          }}
                          disabled={isSupplyEditionDisabled}
                        />
                      </Grid>
                      {sampleData ? (
                        <>
                          <Grid item xs={3.5} xl={2.5}>
                            {isSupplyEditionDisabled ? (
                              <RoundedButton
                                id="edit-supply-factor"
                                model="dark"
                                width="md"
                                onClick={() =>
                                  setIsSupplyEditionDisabled(false)
                                }
                              >
                                {IconEditMS}
                                {Constants.editSupply}
                              </RoundedButton>
                            ) : (
                              <RoundedButton
                                id="save-supply-factor"
                                model="filled"
                                width="md"
                                onClick={handleNewSupplyFactor}
                              >
                                {Constants.save}
                              </RoundedButton>
                            )}
                          </Grid>
                          <SliderGrid item xs={3.5} xl={3}>
                            <Slider
                              aria-label="raio"
                              getAriaValueText={(v) => `${v} km`}
                              value={maxRadius}
                              step={0.5}
                              marks
                              min={0.5}
                              max={2.5}
                              onChange={(_, v) => setMaxRadius(v as number)}
                              disabled={!showCompleteSampleButton()}
                              sx={{ width: '70%' }}
                            />
                            <Typography mx="10px">{maxRadius} km</Typography>
                          </SliderGrid>
                          {showCompleteSampleButton() && (
                            <StyledGrid item xs={3.5} xl={2.5}>
                              <RoundedButton
                                width="md"
                                model="filled"
                                onClick={handleSearchSample}
                                disabled={loading}
                              >
                                {Constants.completeSample}
                              </RoundedButton>
                            </StyledGrid>
                          )}
                          <StyledGrid
                            item
                            xs={showCompleteSampleButton() ? 12 : 3.5}
                            xl={showCompleteSampleButton() ? 2.5 : 5}
                          >
                            <ConfirmationDialog
                              id="delete-sample"
                              text={Constants.deleteSampleText}
                              button={
                                <DeleteButton disabled={loadingDelete}>
                                  {loadingDelete ? (
                                    <WhiteCircularProgress size={22} />
                                  ) : (
                                    <>
                                      {IconDeleteMS}
                                      {Constants.deleteSample}
                                    </>
                                  )}
                                </DeleteButton>
                              }
                              model="error"
                              modalCallback={handleDeleteSample}
                            />
                          </StyledGrid>
                        </>
                      ) : (
                        <>
                          <SliderGrid item xs={3.5} xl={3}>
                            <Slider
                              aria-label="raio"
                              getAriaValueText={(v) => `${v} km`}
                              value={maxRadius}
                              step={0.5}
                              marks
                              min={0.5}
                              max={2.5}
                              onChange={(_, v) => setMaxRadius(v as number)}
                              sx={{ width: '70%' }}
                            />
                            <Typography mx="10px">{maxRadius} km</Typography>
                          </SliderGrid>
                          <StyledGrid item xs={3}>
                            <RoundedButton
                              id="search-sample"
                              className="search-sample"
                              model="filled"
                              width="md"
                              onClick={handleSearchSample}
                            >
                              {IconSearchMS} {Constants.searchSamples}
                            </RoundedButton>
                          </StyledGrid>
                        </>
                      )}
                    </Grid>
                    {!loading && extrapolationsErrors.length > 0 && (
                      <ExtrapolationsErrors>
                        {extrapolationsErrors}
                        <p>{Constants.extrapolation}</p>
                      </ExtrapolationsErrors>
                    )}
                    {loading ? (
                      <LoadingBox>
                        <CircularProgress />
                      </LoadingBox>
                    ) : (
                      sampleData &&
                      sampleData.length > 0 &&
                      propertyData &&
                      completeElements &&
                      incompleteElements && (
                        <Sample
                          disapprovedElements={disapprovedElements}
                          setDisapprovedElements={setDisapprovedElements}
                          propertyData={propertyData}
                          sampleData={sampleData}
                          completeElements={completeElements}
                          incompleteElements={incompleteElements}
                          pinPlace={pinPlace}
                          showMap={showMap}
                          updateSample={getSample}
                          minElements={minElements}
                          page={page}
                          setPage={setPage}
                          setLastElementEdited={setLastElementEdited}
                        />
                      )
                    )}
                    <AddElement
                      isDisabled={
                        sampleData && evaluationTypeIsFactors
                          ? sampleData?.length >= factorsMax
                          : sampleData?.length === inferenceMax
                      }
                      osNumber={osId}
                      updateSample={getSample}
                      highPrice={propertyData?.sample_sell_price_above_400k}
                      propertyType={propertyData?.real_estate_kind}
                    />
                  </>
                )}
              </SectionBox>
              <SubmitBox>
                <PeptDialog
                  osId={osId}
                  referenceNumber={propertyData?.reference_number}
                />
                {enableEditing ? (
                  <FilledButton
                    form="sample"
                    type="submit"
                    disabled={submitLoading}
                  >
                    {submitLoading ? (
                      <CircularProgress size={22} />
                    ) : (
                      Constants.confirmEdit
                    )}
                  </FilledButton>
                ) : showModal ? (
                  <ConfirmationDialog
                    loading={loadingApprove}
                    title={Constants.approve}
                    text={Constants.changeStatus.replace(
                      '**',
                      `${propertyData?.reference_number}`
                    )}
                    modalCallback={() => {
                      setLoadingApprove(true);
                      handleStatus(osId, propertyData?.reference_number);
                    }}
                    errorMessage={
                      parkingError ? Constants.parkingErrorMessage : undefined
                    }
                  />
                ) : (
                  <OutlinedButton
                    onClick={checkApprovedSample}
                    disabled={extrapolationsErrors.length ? true : false}
                  >
                    {isConfirmChangesLoading ? (
                      <CircularProgress size={22} />
                    ) : (
                      Constants.confirmChanges
                    )}
                  </OutlinedButton>
                )}
              </SubmitBox>
            </Box>
          </>
        )}
      </BoxContainer>
      {showAddressModal && (
        <StyledDialog
          open={showAddressModal}
          onClose={() => setShowAddressModal(false)}
          aria-labelledby="address dialog"
        >
          <DialogBox>
            <ButtonBox>
              <CloseIcon onClick={() => setShowAddressModal(false)}>
                {IconCloseMS}
              </CloseIcon>
            </ButtonBox>
            <DialogTypography>{Constants.addressText}</DialogTypography>
          </DialogBox>
        </StyledDialog>
      )}
    </GridContainer>
  );
}
