import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PipelineBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  minWidth: '300px',
  width: '300px',
  textAlign: 'center',
}));

export const PipelineTitle = styled(Typography)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.common.white,
  height: '56px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#000',
  fontWeight: '600',
}));

export const PipelinePaper = styled(Paper)(({ theme }) => ({
  height: '90%',
  overflowY: 'auto',
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  padding: '8px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  boxShadow: 'none',
}));

export const StyledSkeleton = styled(Skeleton)(() => ({
  borderRadius: 5,
  width: '100%',
  backgroundColor: '#E6E6E6',
  margin: '8px 0 16px',
  padding: '16px',
  height: 230,
}));
