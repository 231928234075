import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledDialog } from '../../../components/UI/Dialog';

export const Dialog = styled(StyledDialog)(() => ({
  '& .MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    padding: '90px 105px',
    minWidth: 600,
    width: '60%',
    gap: 20,
    position: 'relative',
    textAlign: 'center',
  },
}));

export const Title = styled(Typography)(() => ({
  fontSize: '37px',
  fontWeight: '600',
  color: '#0B2623',
}));

export const StyledText = styled(Typography)(() => ({
  fontSize: '24px',
  marginBottom: '60px',
  textAlign: 'justify',
}));

export const ButtonContainer = styled(Box)(() => ({
  alignSelf: 'center',
  marginTop: '60px',
}));
