import {
  CircularProgress,
  Switch,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { workOrderAPI } from '../../../../../api';
import { StatusCode } from '../../../../../api/enumerations';
import { IconEditMS } from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import { getErrorMessage } from '../../../../../helpers';
import useGeneral from '../../../../../hooks/useGeneral';
import { FilledButton } from '../../../../UI/Button';
import { StyledDialog } from '../../../../UI/Dialog';
import { SectionTitle } from '../../../../UI/Typography';
import {
  BoldCell,
  CellContent,
  CellHeader,
  RowHeader,
  StyledTable,
  StyledTooltip,
} from '../../styles';
import { DialogContainer, OutlinedButton } from './styles';

interface ForceTransformationsProps {
  refNumber: number;
  transformations: string[];
}

export function Recalculate({
  refNumber,
  transformations,
}: ForceTransformationsProps): JSX.Element {
  const selectedOptions = [
    'inversa',
    'inversa_quadratica',
    'inversa_raiza_quadrada',
    'identidade',
    'quadratica',
    'raiz_quadrada',
    'logaritmo_natural',
  ];

  const [loading, setLoading] = useState(false);
  const [selectedTransformations, setSelectedTransformations] =
    useState(selectedOptions);

  useEffect(() => {
    if (transformations?.length > 0) {
      setSelectedTransformations(transformations);
    }
  }, [transformations]);

  const { osId, navigateHome, handleClose, handleOpen, open } = useGeneral();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const values = [
    { index: 0, name: 'inversa - 1/x', value: 'inversa' },
    {
      index: 1,
      name: 'inversa quadrática - 1/x²',
      value: 'inversa_quadratica',
    },
    {
      index: 2,
      name: 'inversa raiza quadrada - 1/sqrt(x)',
      value: 'inversa_raiza_quadrada',
    },
    { index: 3, name: 'identidade - x', value: 'identidade' },
    { index: 4, name: 'quadratica - x²', value: 'quadratica' },
    {
      index: 5,
      name: 'raiz quadrada - sqrt(x)',
      value: 'raiz_quadrada',
    },
    { index: 6, name: 'logaritmo natural - ln(x)', value: 'logaritmo_natural' },
  ];

  const handleForceTransformations = async (): Promise<void> => {
    setLoading(true);

    const data = {
      force_transformations: selectedTransformations,
    };

    try {
      const { detail } = await workOrderAPI.sendForceFactors(osId, data);

      if (detail.description) {
        throw new Error(detail.description);
      }

      if (detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLoading(false);
      setSnackbarMessage(`OS ${refNumber} aprovada`);
      setOpenSnackbar(true);
      setErrorMessage(false);
      navigateHome();
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <>
      <OutlinedButton
        onClick={handleOpen}
        type="button"
        id="select-transformations"
      >
        {IconEditMS}
        <Typography marginLeft="8px">selecionar transformações</Typography>
      </OutlinedButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <DialogContainer id="transformations-dialog">
          <SectionTitle color="GrayText">
            selecionar transformações
          </SectionTitle>
          <TableContainer sx={{ marginTop: '4px' }}>
            <StyledTable>
              <TableHead>
                <RowHeader>
                  <CellHeader> </CellHeader>
                  <CellHeader align="center">nome</CellHeader>
                  <CellHeader align="center">selecionar</CellHeader>
                </RowHeader>
              </TableHead>
              <TableBody>
                {values.map((value) => (
                  <TableRow key={value.index}>
                    <CellContent align="center">{value.index}</CellContent>
                    <BoldCell>{value.name}</BoldCell>
                    <CellContent align="center">
                      {value.index === 0 || value.index === 3 ? (
                        <StyledTooltip title="seleção obrigatória para o cálculo">
                          <Switch checked />
                        </StyledTooltip>
                      ) : (
                        <Switch
                          checked={selectedTransformations.includes(
                            value.value
                          )}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.checked) {
                              setSelectedTransformations([
                                ...selectedTransformations,
                                value.value,
                              ]);
                            } else {
                              const deleteChoice =
                                selectedTransformations?.filter((element) => {
                                  return element !== value.value;
                                });
                              setSelectedTransformations(deleteChoice);
                            }
                          }}
                        />
                      )}
                    </CellContent>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '32px',
            }}
          >
            <OutlinedButton type="button" onClick={handleClose} id="cancel-btn">
              cancelar
            </OutlinedButton>
            <FilledButton type="button" onClick={handleForceTransformations}>
              {loading ? <CircularProgress size={21} /> : 'confirmar'}
            </FilledButton>
          </div>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
