import { AgendaFinishTour } from './Agenda/AgendaFinishTour';
import { AgendaTour } from './Agenda/AgendaTour';
import { ArchivedFinishTour } from './Archived/ArchivedFinishTour';
import { ArchivedTour } from './Archived/ArchivedTour';
import { CalculationFinish } from './Calculation/CalculationFinish';
import { CalculationTour } from './Calculation/CalculationTour';
import { ConfigurationFinishTour } from './Configuration/ConfigurationFinishTour';
import { ConfigurationTour } from './Configuration/ConfigurationTour';
import { EntranceFinishTour } from './Entrance/EntranceFinishTour';
import { EntranceTour } from './Entrance/EntranceTour';
import { ErrorReportFinishTour } from './ErrorReport/ErrorReportFinishTour';
import { ErrorReportTour } from './ErrorReport/ErrorReportTour';
import { FormFinishTour } from './Form/FormFinishTour';
import { FormTour } from './Form/FormTour';
import { GuidedTour } from './GuidedTour';
import { InspectionFinishTour } from './Inspection/InspectionFinishTour';
import { InspectionTour } from './Inspection/InspectionTour';
import { PeptFinishTour } from './Pept/PeptFinishTour';
import { PeptTour } from './Pept/PeptTour';
import { PipelineFinishTour } from './Pipeline/PipelineFinishTour';
import { PipelineTour } from './Pipeline/PipelineTour';
import { ReportFinishTour } from './Report/ReportFinishTour';
import { ReportTour } from './Report/ReportTour';
import { RevisionFinishTour } from './Revision/RevisionFinishTour';
import { RevisionTour } from './Revision/RevisionTour';
import { SampleCreationFinishTour } from './SampleCreation/SampleCreationFinishTour';
import { SampleCreationTour } from './SampleCreation/SampleCreationTour';
import { ScheduleFinishTour } from './Schedule/ScheduleFinishTour';
import { ScheduleTour } from './Schedule/ScheduleTour';
import { UsersFinishTour } from './Users/UsersFinishTour';
import { UsersTour } from './Users/UsersTour';
import { WelcomeTour } from './Welcome/WelcomeTour';

export const homeTutorial = [
  WelcomeTour,
  GuidedTour,
  PipelineTour,
  PipelineFinishTour,
  EntranceTour,
  EntranceFinishTour,
  PeptTour,
  PeptFinishTour,
  ScheduleTour,
  ScheduleFinishTour,
  InspectionTour,
  InspectionFinishTour,
  SampleCreationTour,
  SampleCreationFinishTour,
  CalculationTour,
  CalculationFinish,
  ReportTour,
  ReportFinishTour,
  RevisionTour,
  RevisionFinishTour,
  ArchivedTour,
  ArchivedFinishTour,
  UsersTour,
  UsersFinishTour,
  FormTour,
  FormFinishTour,
  ConfigurationTour,
  ConfigurationFinishTour,
  AgendaTour,
  AgendaFinishTour,
  ErrorReportTour,
  ErrorReportFinishTour,
];
