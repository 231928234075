import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  minHeight: '800px',
  height: '100vh',
}));

export const Logo = styled(Box)(() => ({
  marginBottom: '40px',
  backgroundColor: '#CC35FF',
  padding: '0.5rem',
  img: {
    height: 77,
    width: 88,
  },
}));

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F5F5F4',
  boxShadow: '0px 1px 2px #61335429',
  padding: '90px 92px',
  width: '70%',
}));

export const StyledIcon = styled(Typography)(() => ({
  span: {
    fontSize: 100,
  },
  marginBottom: '30px',
}));

export const Title = styled(Typography)(() => ({
  fontSize: 37,
  marginBottom: 30,
  fontWeight: 600,
}));

export const StyledText = styled(Typography)(() => ({
  fontSize: '24px',
  marginBottom: '60px',
  textAlign: 'justify',
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  backgroundColor: '#613354',
  fontWeight: '700',
  color: '#D6D4D0',
  textTransform: 'uppercase',
  margin: 'clamp(42px, 6.7vh ,62px) 0',
  boxShadow: '0px 3px 15px #00000029',
  '&:hover': {
    backgroundColor: '#9B7359',
  },
  [theme.breakpoints.down('md')]: {
    width: '280px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
  },
}));

export const ButtonBox = styled(Box)(() => ({
  width: '100%',
  margin: '20px 0',
  display: 'flex',
  gap: '1rem',
  justifyContent: 'space-around',
  marginTop: 60,
}));

export const UnavailableArea = styled(Typography)(() => ({
  fontSize: '24px',
  textAlign: 'center',
  span: {
    fontWeight: 600,
  },
}));
