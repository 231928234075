/* eslint-disable max-len */
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const Constants = {
  welcome: 'Bem-vindo ao Proto',
  unavailable: 'Local indisponível',
  verifyCEP:
    'Para utilizar o sistema Proto, você precisa estar dentro da área de abrangência. Digite seu CEP para que possamos verificar a disponibilidade do seu acesso.',
  unavailableArea: [
    'Verificamos que *ainda* não disponibilizamos nossos serviços para este endereço.',
    ' Mas não se preocupe, estamos em processo de expansão e em breve iremos atender a sua região.',
    ' Você poderá optar por deixar seu email cadastrado, para que possamos entrar em contato no futuro.',
  ],
  later: 'deixar para mais tarde',
  registerEmail: 'cadastrar email',
  interestRegister: 'Registro de interesse',
  registerInfo:
    'Escreva no campo abaixo o seu email para que possamos registrar seu interesse em utilizar nossos serviços assim que estiver disponível na sua localidade:',
  continue: 'continuar',
  email: 'e-mail principal',
  send: 'enviar',
  cnpj: 'CNPJ',
  cpf: 'CPF',
  company: 'nome da empresa',
  subdomain:
    'Escolha um subdomínio para customizar seu acesso ao sistema. ex: realprice.protoai.com.br',
  fullName: 'nome completo',
  responsible: 'responsável',
  telephone: 'telefone',
  cau: 'CAU',
  crea: 'CREA',
  cep: 'CEP',
  adress: 'logradouro',
  number: 'número',
  complement: 'complemento (opcional)',
  district: 'bairro',
  uf: 'UF',
  city: 'cidade',
  password: 'senha',
  repeatPassword: 'repetir senha',
  passwordStrength: 'Força da senha',
  confirm: 'confirmar',
  acceptTerms:
    'Declaro que aceito os *Termos de uso* e a *Política de privacidade.*',
  authenticationText:
    'Precisamos garantir que nossos usuários sejam Engenheiros ou Arquitetos, e para isso é importante estabelecermos um contato com você.',
  emailVerification: 'Código de verificação foi enviado para o e-mail',
  typeEmailCode: 'Digite o código enviado para o seu e-mail',
  typeSMSCode: 'Digite o código enviado para o seu telefone',
  smsVerification: 'Código de verificação foi enviado por SMS para o número',
  sendAgain: 'enviar novamente',
  ready: 'Pronto!',
  registerFinished: 'Cadastro finalizado',
  accessGranted: [
    'Agora nós vamos aguardar a confirmação de identidade e assim que for concluída você receberá um e-mail.',
    'Enquanto isso, você pode ir consumindo alguns conteúdos que produzimos por aqui:',
  ],
};

export const links = [
  {
    id: 1,
    icon: LanguageIcon,
    title: 'www.protoai.com.br',
    link: 'https://protoai.com.br/',
  },
  {
    id: 2,
    icon: LinkedInIcon,
    title: 'linkedin.com/company/protoai-oficial/',
    link: 'https://www.linkedin.com/company/protoai-oficial/',
  },
  {
    id: 3,
    icon: FacebookIcon,
    title: 'protoai.oficial',
    link: 'https://www.facebook.com/protoai.oficial/',
  },
  {
    id: 4,
    icon: InstagramIcon,
    title: 'protoai.oficial',
    link: 'https://www.instagram.com/protoai.oficial/',
  },
];
