import { CircularProgress } from '@mui/material';
import { ChangeEvent, useCallback, useContext, useState } from 'react';

import { companyAPI } from '../../../api';
import { RegisterStage, StatusCode } from '../../../api/enumerations';
import Snackbar from '../../../components/Snackbar';
import { Constants } from '../../../constants/createAccount';
import { GlobalContext } from '../../../context/global';
import { RegistrationIdentificationContext } from '../../../context/registrationIdentification';
import { getErrorMessage, validateEmail } from '../../../helpers';
import { EmailInput, InputBox, StyledText, SubmitButton } from './styles';

interface IdentificationProps {
  handleNextStage: (stage: RegisterStage) => void;
}

export function Identification({
  handleNextStage,
}: IdentificationProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { setIdentificationEmail, setPhoneNumber } = useContext(
    RegistrationIdentificationContext
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      if (validateEmail(email)) {
        try {
          const { data, detail } = await companyAPI.registrationStatus(email);

          if (detail.status_code !== StatusCode.OK && detail.description) {
            throw new Error(detail.description);
          }

          if (data) {
            if (data.status === 1) {
              setIdentificationEmail(email);
              handleNextStage(RegisterStage.REGISTER_FORM);
            }
            if (data.status === 2) {
              setIdentificationEmail(email);
              setPhoneNumber(data.phone_number);
              handleNextStage(RegisterStage.AUTHENTICATION);
            }
            if (data.status === 3) {
              setIdentificationEmail(email);
              setPhoneNumber(data.phone_number);
              handleNextStage(RegisterStage.DOCUMENTS_VERIFICATION);
            }
            setLoading(false);
          }
        } catch (error) {
          setSnackbarMessage(getErrorMessage(error));
          setOpenSnackbar(true);
          setErrorMessage(true);
          setLoading(false);
        }
      } else {
        setSnackbarMessage('Email inválido');
        setOpenSnackbar(true);
        setErrorMessage(true);
        setLoading(false);
      }
    },
    [
      email,
      handleNextStage,
      setErrorMessage,
      setIdentificationEmail,
      setOpenSnackbar,
      setPhoneNumber,
      setSnackbarMessage,
    ]
  );

  return (
    <InputBox component="form" id="identification">
      <StyledText>Informe seu e-mail para prosseguir no cadastro.</StyledText>
      <EmailInput
        label="e-mail principal"
        type="email"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)
        }
      />
      <SubmitButton
        form="identification"
        type="submit"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress color="inherit" size={22} />
        ) : (
          Constants.continue
        )}
      </SubmitButton>
      {openSnackbar && <Snackbar />}
    </InputBox>
  );
}
