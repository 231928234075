import {
  Pagination,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';

import { InvoicesData } from '../../../../api/plans/types';
import { PagesContainer } from '../../../../components/UI/Box';
import { Constants } from '../../../../constants/configuration';
import {
  IconAttachFileMS,
  IconDescriptionMS,
} from '../../../../constants/icons';
import { formatDate } from '../../../../helpers';
import { Cell, Row, StyledTableCell, StyledTableContainer } from './styles';

interface InvoicesTableProps {
  tableData: InvoicesData[];
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
}

export function InvoicesTable({
  tableData,
  page,
  setPage,
  totalPages,
}: InvoicesTableProps): JSX.Element {
  const handleChangePage = (newPage: number): void => {
    setPage(newPage);
  };

  return (
    <StyledTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <Cell align="center" sx={{ borderTopLeftRadius: 8 }}>
              {Constants.description}
            </Cell>
            <Cell align="center">{Constants.value}</Cell>
            <Cell align="center">{Constants.dueDate}</Cell>
            <Cell align="center">{Constants.paymentDate}</Cell>
            <Cell align="center" sx={{ borderTopRightRadius: 8 }}>
              {IconAttachFileMS}
            </Cell>
          </TableRow>
        </TableHead>
        <TableBody id="table">
          {tableData.map((invoices) => (
            <Row key={invoices.id}>
              <StyledTableCell align="center">{invoices.plan}</StyledTableCell>
              <StyledTableCell align="center">
                R${' '}
                {invoices.total.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                })}
              </StyledTableCell>
              <StyledTableCell align="center">
                {formatDate(invoices.due_date)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {invoices.paid_at && formatDate(invoices.paid_at)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {IconDescriptionMS}
              </StyledTableCell>
            </Row>
          ))}
        </TableBody>
      </Table>
      <PagesContainer>
        <Pagination
          page={page}
          count={totalPages}
          onChange={(_, page) => handleChangePage(page)}
        />
      </PagesContainer>
    </StyledTableContainer>
  );
}
