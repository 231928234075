import { Grid } from '@mui/material';
import { Driver } from 'driver.js';
import { useContext, useEffect, useRef, useState } from 'react';

import { IconCloseMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { updateSkipTour } from '../../../helpers/driver/utils';
import { useTour } from '../../../hooks/useTour';
import {
  CloseButton,
  PageName,
  StyledButton,
  StyledModal,
  SubText,
  Title,
} from '../../Modal/styles';
import { Container, SkipButton } from './styles';

interface PipelineTourProps {
  driverObj: Driver | null;
}

export function PipelineTour({ driverObj }: PipelineTourProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);
  const btnRef = useRef<HTMLButtonElement>(null);

  const { setTourOn, setDriveIsActive, setTourCompletion, tourCompletion } =
    useTour();
  const { setHomeView } = useContext(GlobalContext);

  const handleClose = (): void => {
    updateSkipTour(setTourCompletion, 'pipeline', true);
    setTourOn(false);
    setIsOpen(false);
  };

  const handleSkipTour = (): void => {
    setTourCompletion({
      ...tourCompletion,
      skipTour: {
        onBoarding: true,
        pipeline: true,
        pept: true,
        entrance: true,
        schedule: true,
        inspection: true,
        sampleCreate: true,
        calculation: true,
        report: true,
        revision: true,
        archived: true,
        users: true,
        configuration: true,
        form: true,
        agenda: true,
        errorReport: true,
      },
    });
    handleClose();
  };

  const handleStartDrive = (): void => {
    handleClose();
    setHomeView('pipeline');
    setTimeout(() => {
      driverObj?.drive();
      setDriveIsActive(true);
    }, 300);
  };

  useEffect(() => {
    if (btnRef.current) btnRef.current.click();
  }, []);

  return (
    <StyledModal open={isOpen} onClose={handleClose}>
      <Container>
        <SkipButton onClick={handleSkipTour}>pular tour</SkipButton>
        <div className="ponta" />
        <CloseButton onClick={handleClose}>{IconCloseMS}</CloseButton>
        <Grid container direction="column" alignItems="center" marginBottom={4}>
          <Grid item alignItems="center">
            <Title>Vamos começar pelo</Title>
          </Grid>
          <Grid item alignItems="center">
            <PageName>Início/Pipeline</PageName>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center" marginBottom={4}>
          <Grid item>
            <SubText>
              Essa é a primeira tela que você terá acesso ao fazer login no
              sistema Proto.
            </SubText>
          </Grid>
          <Grid item>
            <SubText>
              Ela apresenta algumas funções importantes para lhe auxiliar na
              administração do sistema, apresentaremos com mais detalhe cada
              parte.
            </SubText>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          marginBottom={4}
        >
          <StyledButton onClick={handleStartDrive}>começar!</StyledButton>
        </Grid>
      </Container>
    </StyledModal>
  );
}
