/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  ChangeEvent,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { formsAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { InspectionFormData, QuestionData } from '../../../api/forms/types';
import {
  CustomFormControl,
  CustomTextField,
  SelectTextField,
} from '../../../components/CustomInput';
import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog';
import { Title } from '../../../components/Sections/Title';
import { BoxContainer } from '../../../components/UI/Box';
import { BackButton } from '../../../components/UI/Button';
import { GridContainer } from '../../../components/UI/Grid';
import { Constants } from '../../../constants/forms';
import {
  IconArrowCircleLeftMS,
  IconDeleteMS,
  IconDownloadMS,
  IconInfoMS,
  IconKeyboardDoubleArrowDownMS,
  IconKeyboardDoubleArrowLeftMS,
  IconKeyboardDoubleArrowRightMS,
  IconKeyboardDoubleArrowUpMS,
  IconListAltMS,
  IconUploadMS,
} from '../../../constants/icons';
import {
  selectClientKind,
  selectFormPropertyType,
} from '../../../constants/selectOptions';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import { localQuestionsData } from './localQuestionsData';
import NewQuestionDialog from './Question/NewQuestionDialog';
import { QuestionCard } from './Question/QuestionCard';
import { SelectedQuestionCard } from './Question/SelectedQuestionCard';
import {
  FilledDarkButton,
  FlexBox,
  GridButton,
  GridQuestions,
  IconButtonBase,
  OutlinedButton,
  OutlinedDarkButton,
  PaperQuestions,
  StyledTooltip,
  TitleQuestions,
} from './styles';
import { ViewQuestions } from './ViewQuestionsDialog';

interface JumpProps {
  title: string;
  jumpTo: number | null;
  jumpToSelectedTitle: string | undefined;
  jumpToBankTitle: string | undefined;
}

export function FormCreation(): JSX.Element {
  const [formName, setFormName] = useState('');
  const [formModels, setFormModels] = useState<InspectionFormData[]>([]);
  const [useModel, setUseModel] = useState<number>(0);
  const [clientType, setClientType] = useState(0);
  const [propertyType, setPropertyType] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [questions, setQuestions] = useState<QuestionData[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<QuestionData[]>(
    []
  );
  const [checkedBankQ, setCheckedBankQ] = useState<QuestionData[]>([]);
  const [checkedSelectQ, setCheckedSelectQ] = useState<QuestionData[]>([]);
  const [newForm, setNewForm] = useState(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [checkAllSelected, setCheckAllSelected] = useState(false);
  const [checkAllBank, setCheckAllBank] = useState(false);

  const { setErrorMessage, setOpenSnackbar, setSnackbarMessage } =
    useContext(GlobalContext);
  const { checkPathname } = useGeneral();

  const { getErrorMessage } = useErrorMessage();

  const navigate = useNavigate();
  const { id } = useParams();
  const formId = parseInt(id as string, 10);

  const getDataCallback = useCallback(async () => {
    if (checkPathname('/edit')) {
      setNewForm(false);
      try {
        const response = await formsAPI.getInspectionForm(formId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setFormName(response.data.title);
        setClientType(response.data.client_kind);
        setPropertyType(response.data.real_estate_type);

        setShowProgress(response.data.selected_questions.show_progress);
        setSelectedQuestions(response.data.selected_questions.json);

        if (response.data.question_bank) {
          setQuestions(response.data.question_bank);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    } else {
      try {
        const response = await formsAPI.getAllInspectionForms(true);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('Algo deu errado, tente novamente.');
        }
        setSelectedQuestions(localQuestionsData);
        setFormModels(response.data);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const handleFormModel = (): void => {
    const model = formModels.find((model) => model.id === useModel);
    if (model) {
      setFormName(model.title);
      setClientType(model.client_kind);
      setPropertyType(model.real_estate_type);
      setShowProgress(model.selected_questions.show_progress);
      setSelectedQuestions(model.selected_questions.json);
      if (model.question_bank) {
        setQuestions(model.question_bank);
      }
    }
  };

  useEffect(() => {
    if (useModel !== 0) {
      handleFormModel();
    }
  }, [useModel]);

  const handleNewId = (): number => {
    const idArray = [...questions, ...selectedQuestions];
    if (idArray.length === 0) {
      return 1;
    }
    // checks biggest id on both lists before creating new one
    const ids = idArray.map((item) => item.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  };

  const handleQuestionUp = (): void => {
    if (checkedSelectQ.length > 1) {
      setSnackbarMessage('Selecione apenas uma pergunta!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (checkedSelectQ.length === 0) {
      setSnackbarMessage('Selecione uma pergunta!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const checkedQuestion = checkedSelectQ[0];
    const questionIndex = selectedQuestions.indexOf(checkedQuestion);

    if (questionIndex === 0) return;

    const newIndex = questionIndex - 1;

    // uses splice to move question up on the list
    selectedQuestions.splice(questionIndex, 1);
    selectedQuestions.splice(newIndex, 0, checkedQuestion);

    setSelectedQuestions([...selectedQuestions]);
  };

  const handleQuestionDown = (): void => {
    if (checkedSelectQ.length > 1) {
      setSnackbarMessage('Selecione apenas uma pergunta!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (checkedSelectQ.length === 0) {
      setSnackbarMessage('Selecione uma pergunta!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const checkedQuestion = checkedSelectQ[0];
    const questionIndex = selectedQuestions.indexOf(checkedQuestion);

    if (questionIndex > selectedQuestions.length) return;

    const newIndex = questionIndex + 1;

    // uses splice to move question down on the list
    selectedQuestions.splice(questionIndex, 1);
    selectedQuestions.splice(newIndex, 0, checkedQuestion);

    setSelectedQuestions([...selectedQuestions]);
  };

  useEffect(() => {
    if (checkAllBank) {
      setCheckedBankQ(questions);
    } else {
      setCheckedBankQ([]);
    }
  }, [checkAllBank]);

  useEffect(() => {
    if (checkAllSelected) {
      setCheckedSelectQ(selectedQuestions);
    } else {
      setCheckedSelectQ([]);
    }
  }, [checkAllSelected]);

  const addSelectedQuestion = (): void => {
    setSelectedQuestions([...selectedQuestions, ...checkedBankQ]);

    // filters questions to return the ones that are not included in checkedBankQ
    const newBankQuestions = questions.filter((element) => {
      return !checkedBankQ.includes(element);
    });

    setQuestions(newBankQuestions);
    setCheckAllBank(false);
  };

  const removeSelectedQuestion = (): void => {
    const filterSelected = checkedSelectQ.filter((element) => {
      return !element.blocked;
    });

    setQuestions([...questions, ...filterSelected]);

    // filters questions to return the ones that are not included in checkedSelectQ
    const newSelectedQuestions = selectedQuestions.filter((element) => {
      return !filterSelected.includes(element);
    });

    setSelectedQuestions(newSelectedQuestions);
    setCheckAllSelected(false);
  };

  const checkJumpTo = (): JumpProps[] => {
    const questionWithDependencies = selectedQuestions
      .filter((question) => question.choices.find((e) => e.jump_to !== null))
      .map((question) => {
        return question.choices
          .filter((choice) => choice.jump_to !== null)
          .map((choice) => {
            const jumpQuestion = selectedQuestions.find(
              (q) => q.id === choice.jump_to
            );
            return {
              title: `${question.id}. ${question.title}`,
              jumpTo: choice.jump_to,
              jumpToSelectedTitle: jumpQuestion?.title,
              jumpToBankTitle: jumpQuestion?.title,
            };
          });
      });

    return questionWithDependencies
      .map((question) =>
        question.filter((choice) => choice.jumpToSelectedTitle === undefined)
      )
      .flat();
  };

  const handleSubmit = async (): Promise<void> => {
    if (!formName || clientType === 0 || propertyType === 0) {
      setSnackbarMessage('Preencha os dados obrigatórios!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const missingQuestions = checkJumpTo();

    if (missingQuestions.length > 0) {
      setSnackbarMessage(
        // eslint-disable-next-line max-len
        `${missingQuestions[0].jumpToBankTitle} é utilizida em ${missingQuestions[0].title}. Mova-a do banco de perguntas para perguntas selecionadas.`
      );
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const formQuestions = {
      show_progress: showProgress,
      json: selectedQuestions,
    };

    const inspectionForm = {
      client_kind: clientType,
      question_bank: JSON.stringify(questions),
      real_estate_type: propertyType,
      selected_questions: JSON.stringify(formQuestions),
      title: formName,
    };

    if (checkPathname('/edit')) {
      try {
        const response = await formsAPI.updateInspectionForm(
          formId,
          inspectionForm
        );

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Formulário editado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        navigate('/forms');
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    } else {
      try {
        const response = await formsAPI.addInspectionForm(inspectionForm);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }
        setSnackbarMessage('Formulário criado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        navigate('/forms');
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  };

  const handleDeleteForm = async (): Promise<void> => {
    if (formId) {
      try {
        const response = await formsAPI.deleteInspectionForm(formId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Formulário deletado!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        navigate('/forms');
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  };

  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileJSONChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        try {
          const parsedData = JSON.parse(content);
          setQuestions([...questions, ...parsedData]);
        } catch (error) {
          setSnackbarMessage(getErrorMessage(error));
          setErrorMessage(true);
          setOpenSnackbar(true);
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleDownloadJson = (): void => {
    const removeDefaultQuestions = selectedQuestions.filter(
      (question) =>
        !localQuestionsData.some(
          (localQuestion) =>
            question.title === localQuestion.title &&
            question.id === localQuestion.id
        )
    );
    const JSONdata = JSON.stringify(removeDefaultQuestions);

    const blob = new Blob([JSONdata], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.json';
    a.click();

    URL.revokeObjectURL(url);
  };

  return (
    <GridContainer>
      <BackButton onClick={() => navigate('/forms')}>
        {IconArrowCircleLeftMS}
      </BackButton>
      <BoxContainer>
        <Title
          icon={IconListAltMS}
          title={newForm ? Constants.createForm : Constants.editForm}
        />
        <Grid container spacing={3} sx={{ marginBottom: '20px' }}>
          <Grid item xs={4} className="form-model">
            <TextField
              select
              disabled={checkPathname('/edit')}
              id="form-model"
              color="secondary"
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                >
                  {Constants.formModels}
                  <StyledTooltip
                    placement="right-start"
                    title={Constants.toolTipText}
                  >
                    <span style={{ color: '#B038FA' }}>{IconInfoMS}</span>
                  </StyledTooltip>
                </Box>
              }
              value={useModel}
              onChange={(e) => {
                setUseModel(Number(e.target.value));
              }}
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  borderRadius: '16px',
                },
                '& .MuiSvgIcon-root': {
                  color: (theme) => theme.palette.primary.main,
                  fontSize: '2rem',
                },
              }}
            >
              <MenuItem
                disabled
                value={0}
                sx={{
                  color: '#000000',
                  fontStyle: 'normal',
                }}
              >
                <Typography>selecione uma opção</Typography>
              </MenuItem>
              {formModels.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  sx={{
                    '&:hover': { backgroundColor: '#d485eed1' },
                  }}
                >
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1} xl={2.5} />
          <Grid item xs={3} xl={2.5}>
            <input
              type="file"
              ref={fileInputRef}
              accept=".json"
              multiple={false}
              onChange={handleFileJSONChange}
              style={{ display: 'none' }}
            />
            <IconButtonBase onClick={handleButtonClick}>
              {IconUploadMS}
              {Constants.importQuestions}
            </IconButtonBase>
          </Grid>
          <Grid item xs={2} xl={1.5}>
            <IconButtonBase
              disabled={!selectedQuestions}
              onClick={handleDownloadJson}
            >
              {IconDownloadMS}
              {Constants.download}
            </IconButtonBase>
          </Grid>
          <Grid item xs={2} xl={1.5}>
            <ConfirmationDialog
              text={Constants.deleteForm}
              button={
                <IconButtonBase>
                  {IconDeleteMS}
                  {Constants.delete}
                </IconButtonBase>
              }
              model="error"
              modalCallback={handleDeleteForm}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ marginBottom: '40px' }}
          className="form-info"
        >
          <Grid item xs={4}>
            <CustomTextField
              required
              id="form-name"
              label="título"
              value={formName}
              setValue={setFormName}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectTextField
              id="client-type"
              label="tipo do cliente"
              value={clientType}
              setValue={setClientType}
              selectOptions={selectClientKind()}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectTextField
              id="property-type"
              label="tipo do imóvel"
              value={propertyType}
              setValue={setPropertyType}
              selectOptions={selectFormPropertyType()}
            />
          </Grid>
          <Grid item xs={4} xl={3} sx={{ marginTop: '-10px' }}>
            <CustomFormControl
              label={Constants.showProgress}
              isChecked={showProgress}
              setIsChecked={setShowProgress}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <GridQuestions item xs={4.5} id="question-bank">
            <TitleQuestions>{Constants.questionBank}</TitleQuestions>
            <CustomFormControl
              label={Constants.selectAll}
              isChecked={checkAllBank}
              setIsChecked={setCheckAllBank}
            />
            <PaperQuestions>
              {questions.map((data, i) => (
                <QuestionCard
                  key={data.id}
                  question={data}
                  orderNumber={i + 1}
                  questions={questions}
                  setQuestions={setQuestions}
                  checkedQ={checkedBankQ}
                  setCheckedQ={setCheckedBankQ}
                  allQuestions={questions.concat(selectedQuestions)}
                  checkAll={checkAllBank}
                />
              ))}
            </PaperQuestions>
            <NewQuestionDialog
              newQuestionId={handleNewId()}
              questions={questions}
              setQuestions={setQuestions}
              allQuestions={questions.concat(selectedQuestions)}
            />
          </GridQuestions>
          <GridButton item xs={2.5} id="btn-container">
            <OutlinedButton onClick={addSelectedQuestion}>
              {Constants.add} {IconKeyboardDoubleArrowRightMS}
            </OutlinedButton>
            <OutlinedButton onClick={removeSelectedQuestion}>
              {IconKeyboardDoubleArrowLeftMS} {Constants.remove}
            </OutlinedButton>
          </GridButton>
          <GridQuestions item xs={4.5} id="selected-questions">
            <TitleQuestions>{Constants.selectedQuestions}</TitleQuestions>
            <CustomFormControl
              label={Constants.selectAll}
              isChecked={checkAllSelected}
              setIsChecked={setCheckAllSelected}
            />
            <PaperQuestions>
              {selectedQuestions.map((data, i) => (
                <SelectedQuestionCard
                  key={data.id}
                  question={data}
                  orderNumber={i + 1}
                  checkedQ={checkedSelectQ}
                  setCheckedQ={setCheckedSelectQ}
                  checkAll={checkAllSelected}
                />
              ))}
            </PaperQuestions>
            <ViewQuestions questions={selectedQuestions} />
            <FlexBox id="cancel-save-btn-container">
              <OutlinedDarkButton onClick={() => navigate('/forms')}>
                {Constants.cancel}
              </OutlinedDarkButton>
              <FilledDarkButton onClick={handleSubmit}>
                {Constants.save}
              </FilledDarkButton>
            </FlexBox>
          </GridQuestions>
          <GridButton item xs={0.5}>
            <OutlinedButton onClick={handleQuestionUp}>
              {IconKeyboardDoubleArrowUpMS}
            </OutlinedButton>
            <OutlinedButton onClick={handleQuestionDown}>
              {IconKeyboardDoubleArrowDownMS}
            </OutlinedButton>
          </GridButton>
        </Grid>
      </BoxContainer>
    </GridContainer>
  );
}
