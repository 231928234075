export const Constants = {
  calculation: 'processando cálculo',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  noInspection: 'esta OS não possui agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  form: 'Formulário',
  noForm: 'esta OS não possui Formulário',
  sample: 'Amostra',
  previousStatus: 'voltar status',
  previousStatusName: 'Criação de Amostra',
  previousStatusText:
    'alterar o status da OS ** de Processando Cálculo para Criação de Amostra',
  noSample: 'esta OS não possui amostra',
  queuePosition: 'Lugar na fila: ',
  avmFailedHint:
    // eslint-disable-next-line max-len
    ' Volte o status da OS para criação de amostra e troque os elementos na busca automática ou adicione manualmente.',
};
