/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { Box, CircularProgress, Typography } from '@mui/material';
import { driver, DriveStep } from 'driver.js';
import { useEffect, useState } from 'react';

import { EvaluationType, StepTour } from '../../api/enumerations';
import { AvmFactorsData, AvmInferenceData } from '../../api/workOrders/types';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { PeptDialog } from '../../components/Dialog/PeptDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { ReportInfo } from '../../components/Sections/ReportInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  ButtonsSpace,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
  SectionBox,
  SubmitBox,
} from '../../components/UI/Box';
import { BackButton, CancelOSButton } from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconCancelMS,
  IconEditDocumentMS,
  IconEngineeringMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { Constants } from '../../constants/report';
import { driverConfig } from '../../helpers/driver/config';
import { reportSteps } from '../../helpers/driver/steps';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import { FactorsDescription } from './FactorsDescription';
import useReport from './hooks';
import { InferenceDescription } from './InferenceDescription';
import { InspectionSelectedPhotos } from './InspectionSelectedPhotos';
import { AvmStatusBox, BackToSamplesButton } from './styles';

export default function Report(): JSX.Element {
  const [tourHasStarted, setTourHasStarted] = useState(false);
  const [stepTour, setStepTour] = useState<DriveStep[]>();
  const [isFactors, setIsFactors] = useState(false);

  const { navigateHome, osId } = useGeneral();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { handlePreviousStatus, loadingPreviousStatus } = useChangeStatus();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandTen,
    setExpandTen,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();
  const {
    propertyData,
    sampleData,
    hasAvmReport,
    rooms,
    selectedPictures,
    getCharts,
    handleChangeStatus,
    removeSelectedPicture,
    forceFactors,
    forceTransformations,
    loadingApprove,
    loadingPage,
    avmStatus,
  } = useReport();

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    isTourOn,
    setTourOn,
    setToBeContinued,
    tourSelection,
    setTourSelection,
    driveIsActive,
    setDriveIsActive,
  } = useTour();

  const getTourSteps = (evaluationType: number): DriveStep[] => {
    switch (evaluationType) {
      case EvaluationType.AUTOFACTORS:
        return [
          {
            element: '#automated-calculation',
            popover: {
              description: 'Os dados do cálculo aparecerão nesta aba.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#additional-fields',
            popover: {
              description:
                'Nos campos complementares você deverá adicionar ou alterar algumas informações que serão exibidas no laudo final.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#inspection-images',
            popover: {
              description:
                'Aqui você poderá selecionar algumas fotos da vistoria para compor o relatório, caso seja necessário.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#back-to-sample',
            popover: {
              description:
                'Caso seja necessário retornar a Ordem de Serviço para o status de "Criação de Amostra", você poderá clicar neste botão, efetuar as alterações necessárias e enviar a OS para cálculo novamente.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#pept-button',
            popover: {
              description:
                'Caso exista alguma pendência técnica que impeça o andamento da ordem de serviço, você poderá clicar neste botão para enviá-la para o status de pendência.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#force-factors',
            popover: {
              description:
                'Caso você queira, após analisar os resultados, forçar algum fator específico para ser utilizado no cálculo, basta clicar neste botão. (eu exibiria apenas o botão nesse caso).',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#factors-dialog',
            popover: {
              description:
                'Aqui nós mostraremos as validações dos coeficientes para o cálculo atual, você poderá selecionar o fator que gostaria que fosse utilizado no cálculo e em seguida, clicar em confirmar para mandar a OS novamente para o cálculo.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#approve-btn',
            popover: {
              description:
                'Quando o cálculo for validado pelo vistoriador, ele poderá clica em aprovar, para enviar a OS para o status seguinte.',
              side: 'top',
              align: 'start',
            },
          },
        ];
      case EvaluationType.SIMPFACTORS:
        return [
          {
            element: '#automated-calculation',
            popover: {
              description: 'Os dados do cálculo aparecerão nesta aba.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#additional-fields',
            popover: {
              description:
                'Nos campos complementares você deverá adicionar ou alterar algumas informações que serão exibidas no laudo final.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#inspection-images',
            popover: {
              description:
                'Aqui você poderá selecionar algumas fotos da vistoria para compor o relatório, caso seja necessário.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#back-to-sample',
            popover: {
              description:
                'Caso seja necessário retornar a Ordem de Serviço para o status de "Criação de Amostra", você poderá clicar neste botão, efetuar as alterações necessárias e enviar a OS para cálculo novamente.',
              side: 'top',
              align: 'center',
            },
          },
          {
            element: '#pept-button',
            popover: {
              description:
                'Caso exista alguma pendência técnica que impeça o andamento da ordem de serviço, você poderá clicar neste botão para enviá-la para o status de pendência.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#force-factors',
            popover: {
              description:
                'Aqui nós mostraremos as validações dos coeficientes para o cálculo atual, você poderá selecionar o fator que gostaria que fosse utilizado no cálculo e em seguida, clicar em confirmar para mandar a OS novamente para o cálculo.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#factors-dialog',
            popover: {
              description:
                'Aqui você poderá selecionar o fator que deseja forçar a utilização no cálculo, caso você queira enviar o cálculo sem fator nenhum, basta confirmar que a OS será enviada para o cálculo. Lembrando que, o fator de áreas não pode ser desligado.',
              side: 'top',
              align: 'start',
            },
          },
          {
            element: '#approve-btn',
            popover: {
              description:
                'Quando o cálculo for validado pelo vistoriador, ele poderá clica em aprovar, para enviar a OS para o status seguinte.',
              side: 'top',
              align: 'start',
            },
          },
        ];
      case EvaluationType.SIMPINFERENCES:
        return reportSteps;
      case EvaluationType.AUTOINFERENCES:
        return reportSteps;
      default:
        return [];
    }
  };

  useEffect(() => {
    let steps: DriveStep[] = [];
    if (propertyData?.evaluation_type) {
      steps = getTourSteps(propertyData.evaluation_type);
    }
    setStepTour(steps);

    const isFactor =
      propertyData?.evaluation_type === EvaluationType.AUTOFACTORS ||
      propertyData?.evaluation_type === EvaluationType.SIMPFACTORS;
    setIsFactors(isFactor);
  }, [propertyData]);

  const driverObj = driver({
    ...driverConfig,
    steps: stepTour,
    onPrevClick: () => {
      if (driverObj.getActiveIndex() === 6 && isFactors) {
        const btnCancel = document.getElementById('cancel-btn');
        if (btnCancel) btnCancel.click();
      }
      if (driverObj.getActiveIndex() === 7 && isFactors) {
        const btn = document.getElementById('force-factors');
        if (btn) btn.click();
        setTimeout(() => {
          driverObj.moveTo(6);
        }, 300);
      }
      driverObj.movePrevious();
    },
    onNextClick: () => {
      if (driverObj.isLastStep()) {
        setTourCompletion({
          ...tourCompletion,
          report: { complete: true },
          lastStepSeen: StepTour.REPORTFINISH,
        });
        setTutorialStep(StepTour.REPORTFINISH);
        setToBeContinued(false);
        setTourSelection(false);
        setDriveIsActive(false);
        setTourOn(true);
      }
      if (driverObj.getActiveIndex() === 5 && isFactors) {
        const btn = document.getElementById('force-factors');
        if (btn) btn.click();
        setTimeout(() => {
          driverObj.moveTo(6);
        }, 300);
      }
      if (driverObj.getActiveIndex() === 6 && isFactors) {
        const btnCancel = document.getElementById('cancel-btn');
        if (btnCancel) btnCancel.click();
      }
      driverObj.moveNext();
    },
    onCloseClick: () => {
      setDriveIsActive(false);
      setTourSelection(false);
      setToBeContinued(false);
      setIsFactors(false);
      driverObj.destroy();
    },
    onDestroyStarted: () => {
      setDriveIsActive(false);
      setTourSelection(false);
      setToBeContinued(false);
      setIsFactors(false);
      driverObj?.destroy();
    },
  });

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven &&
      expandEight &&
      expandNine &&
      expandTen
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
    expandEight,
    expandNine,
    expandTen,
  ]);

  useEffect(() => {
    if (!tourCompletion.report.complete && !tourCompletion.skipTour.report) {
      setTutorialStep(StepTour.REPORTSTART);
      if (!driveIsActive && !tourHasStarted) {
        setTourOn(true);
        setTourHasStarted(true);
      } else if (driveIsActive && !isTourOn) {
        driverObj.drive();
      }
    } else if (tourSelection && driveIsActive && !isTourOn) {
      setTutorialStep(StepTour.REPORTSTART);
      setTimeout(() => {
        driverObj.drive();
      }, 250);
    }
  }, [tourSelection, driveIsActive, isTourOn]);

  useEffect(() => {
    setExpandEight(true);
  }, []);

  const showSchedulingAccordion =
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPINFERENCES;

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.reportPreparation}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDialog
                  text={Constants.cancelOsText}
                  button={
                    <CancelOSButton>
                      {IconCancelMS}
                      {Constants.cancelOs}
                    </CancelOSButton>
                  }
                  model="error"
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <AttachFilesDialog propertyData={propertyData} osId={osId} />
            </FlexSpaceBetweenBox>
            {avmStatus.length > 0 && (
              <AvmStatusBox>
                {avmStatus}
                {Constants.avmFailedHint}
              </AvmStatusBox>
            )}
            <ClientData propertyData={propertyData} />
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
              {showSchedulingAccordion && (
                <SectionBox>
                  <AccordionTitle
                    title={Constants.scheduleInfo}
                    icon={IconCalendarTodayMS}
                    openAccordion={expandFour}
                    setOpenAccordion={setExpandFour}
                  />
                  {expandFour && (
                    <Box>
                      {propertyData?.inspection ? (
                        <ScheduleInfo
                          inspectionData={propertyData.inspection}
                          rooms={rooms}
                        />
                      ) : (
                        <Typography>{Constants.noInspection}</Typography>
                      )}
                    </Box>
                  )}
                </SectionBox>
              )}
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response ? (
                      <FormResponse
                        questionForm={propertyData.inspection.form_response}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.sample}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <Box>
                    {sampleData && sampleData?.length > 0 ? (
                      <SampleSection
                        sampleData={sampleData}
                        propertyData={propertyData}
                      />
                    ) : (
                      <Typography>{Constants.noSample}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox id="automated-calculation">
                <AccordionTitle
                  title={Constants.report}
                  icon={IconEngineeringMS}
                  openAccordion={expandEight}
                  setOpenAccordion={setExpandEight}
                />
                {expandEight && (
                  <Box>
                    {hasAvmReport && propertyData ? (
                      <ReportInfo
                        propertyData={propertyData}
                        getCharts={getCharts}
                        forceFactors={forceFactors}
                        forceTransformations={forceTransformations}
                      />
                    ) : (
                      <Box>{Constants.reportError}</Box>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox id="additional-fields">
                <AccordionTitle
                  title={Constants.complementaryFields}
                  icon={IconEditDocumentMS}
                  openAccordion={expandNine}
                  setOpenAccordion={setExpandNine}
                />
                {expandNine && (
                  <Box>
                    {hasAvmReport ? (
                      propertyData?.evaluation_type ===
                      EvaluationType.AUTOINFERENCES ? (
                        <InferenceDescription
                          tableData={
                            propertyData?.avm_report as AvmInferenceData
                          }
                          osId={osId}
                        />
                      ) : (
                        <FactorsDescription
                          tableData={propertyData?.avm_report as AvmFactorsData}
                          osId={osId}
                        />
                      )
                    ) : (
                      <Box>{Constants.reportError}</Box>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox id="inspection-images">
                <AccordionTitle
                  title="Imagens vistoria"
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandTen}
                  setOpenAccordion={setExpandTen}
                />
                {expandTen && (
                  <Box>
                    {propertyData?.inspection?.id && rooms.length > 0 ? (
                      <InspectionSelectedPhotos
                        selectedPhotos={selectedPictures}
                        inspectionId={propertyData?.inspection?.id}
                        removeSelection={removeSelectedPicture}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SubmitBox>
                <ButtonsSpace>
                  <ConfirmationDialog
                    id="back-to-sample"
                    text={Constants.backStatusText.replace(
                      '**',
                      `${propertyData?.reference_number}`
                    )}
                    button={
                      <BackToSamplesButton disabled={loadingPreviousStatus}>
                        {loadingPreviousStatus ? (
                          <CircularProgress color="inherit" size={22} />
                        ) : (
                          Constants.backToSample
                        )}
                      </BackToSamplesButton>
                    }
                    modalCallback={() =>
                      handlePreviousStatus(
                        osId,
                        propertyData?.reference_number,
                        Constants.previousStatusName
                      )
                    }
                  />
                  <PeptDialog
                    osId={osId}
                    referenceNumber={propertyData?.reference_number}
                  />
                </ButtonsSpace>
                <ConfirmationDialog
                  id="approve-btn"
                  loading={loadingApprove}
                  title={Constants.approve}
                  text={Constants.changeStatus.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  modalCallback={() => handleChangeStatus()}
                />
              </SubmitBox>
            </Box>
          </>
        )}
      </BoxContainer>
    </GridContainer>
  );
}
