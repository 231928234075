import {
  TableCell,
  TableRow,
  TableContainer,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableContainer = styled(TableContainer)(() => ({
  width: '100%',
  paddingBottom: '10px',
}));

export const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '600',
    fontSize: '16px',
    color: '#F2F2F2',
    backgroundColor: theme.palette.primary.dark,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderRight: '1px solid #F2F2F2',
    '&:last-child': {
      borderRight: '0',
    },
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: '600',
  padding: '12px',
  fontSize: '16px',
  borderLeft: `1px solid ${theme.palette.primary.dark}`,
  borderBottom: `1px solid ${theme.palette.primary.dark}`,
  '&:last-child': {
    borderRight: `1px solid ${theme.palette.primary.dark}`,
  },
}));

export const Row = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:nth-of-type(odd)': {
    backgroundColor: '#E6DCEB7D',
  },
  '&:hover': {
    backgroundColor: '#d485eed1',
    '& > td': {
      color: theme.palette.common.white,
    },
  },
}));
