import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledDialog } from '../../../components/UI/Dialog';
import { theme } from '../../../styles/theme';

export const Dialog = styled(StyledDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minHeight: '600px',
    padding: '60px 80px',
    [theme.breakpoints.down('lg')]: {
      width: '800px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1200px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '1500px',
    },
  },
}));

export const SectionBox = styled(Box)(() => ({
  marginBottom: '40px',
}));

export const SectionSubtitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '20px',
  '& span': { color: theme.palette.primary.main },
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '40px',
}));

export const CoordGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2rem',
  justifyContent: 'center',
  border: '1px solid #D7D8DB',
  backgroundColor: theme.palette.common.white,
  borderRadius: '10px',
  padding: '8px 0',
  margin: '0.5rem 0 1rem',
}));

export const CoordBox = styled(Box)(() => ({
  display: 'flex',
  gap: 10,
  color: theme.palette.primary.main,
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: '600',
}));
