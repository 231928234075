import { Grid } from '@mui/material';
import { useState } from 'react';

import {
  QuestionFormData,
  QuestionResponseData,
} from '../../../api/workOrders/types';
import {
  IconKeyboardDoubleArrowLeftMS,
  IconKeyboardDoubleArrowRightMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/inspection';
import { Question } from './Question';
import { QuestionItem } from './QuestionItem';
import {
  NavigationBox,
  OutlinedButton,
  PaperQuestions,
  QuestionsBox,
  SelectTypography,
} from './styles';

interface FormResponseProps {
  questionForm: QuestionResponseData;
}

export function FormResponse({ questionForm }: FormResponseProps): JSX.Element {
  const [questionData, setQuestionData] = useState<QuestionFormData>();
  const [questionNum, setQuestionNum] = useState(0);

  const navigateNext = (): void => {
    if (!questionForm) return;
    if (questionForm.json.length === questionNum) {
      setQuestionData(questionForm.json[0]);
      setQuestionNum(1);
      return;
    }
    setQuestionData(questionForm.json[questionNum]);
    setQuestionNum(questionNum + 1);
  };

  const navigateLast = (): void => {
    if (!questionForm) return;
    if (questionNum === 1) {
      const total1Q = questionForm.json.length;
      setQuestionData(questionForm.json[total1Q - 1]);
      setQuestionNum(total1Q);
      return;
    }
    setQuestionData(questionForm.json[questionNum - 2]);
    setQuestionNum(questionNum - 1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <PaperQuestions>
          {questionForm?.json.map((data, i) => (
            <QuestionsBox
              key={data.id}
              open={questionNum === i + 1}
              onClick={() => {
                setQuestionData(data);
                setQuestionNum(i + 1);
              }}
            >
              <QuestionItem
                title={data.title}
                questionNum={i + 1}
                checkAnswer={!!(data.answer && data.answer !== null)}
              />
            </QuestionsBox>
          ))}
        </PaperQuestions>
      </Grid>
      <Grid item xs={8}>
        {questionData ? (
          <>
            <Question question={questionData} questionNum={questionNum} />
            <NavigationBox>
              <OutlinedButton onClick={navigateNext}>
                {IconKeyboardDoubleArrowRightMS}
              </OutlinedButton>
              <OutlinedButton onClick={navigateLast}>
                {IconKeyboardDoubleArrowLeftMS}
              </OutlinedButton>
            </NavigationBox>
          </>
        ) : (
          <SelectTypography>{Constants.selectQuestion}</SelectTypography>
        )}
      </Grid>
    </Grid>
  );
}
