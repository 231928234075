/* eslint-disable no-useless-escape */
import { useEffect, useState } from 'react';

import { Container, StrenghContainer, StrenghLine, Text } from './styles';

interface PasswordStrengthProps {
  password: string;
}

export default function PasswordStrength({
  password,
}: PasswordStrengthProps): JSX.Element {
  const [strengthLevel, setStrengthLevel] = useState(0);

  useEffect(() => {
    let newStrengthLevel = 0;

    // Verifica se a senha possui no mínimo 8 caracteres.
    if (password.length >= 8) {
      newStrengthLevel += 1;
    }
    // Verifica se a senha possui número.
    if (/.*\d.*/.test(password)) {
      newStrengthLevel += 1;
    }
    // Verifica se a senha possui letra minúscula.
    if (/[a-z]/.test(password)) {
      newStrengthLevel += 1;
    }
    // Verifica se a senha possui letra maiúscula.
    if (/[A-Z]/.test(password)) {
      newStrengthLevel += 1;
    }

    setStrengthLevel(newStrengthLevel);
  }, [password]);

  return (
    <Container>
      <Text>Força da senha</Text>
      <StrenghContainer>
        <StrenghLine strengthlevel={strengthLevel} />
      </StrenghContainer>
    </Container>
  );
}
